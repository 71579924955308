import { BusinessTypesIds } from 'src/types';

// note: the below values need to be kept in sync with paytron ptrn-fe companyTypes OfxEntityType
export default {
  [BusinessTypesIds.PrivateCompany]: 'Private Company / Pty / Ltd',
  [BusinessTypesIds.SoleTrader]: 'SoleTrader',
  [BusinessTypesIds.Partnership]: 'Partnership',
  [BusinessTypesIds.Trust]: 'Trust',
  [BusinessTypesIds.NonProfit]: 'NonProfit',
  [BusinessTypesIds.PublicCompany]: 'PublicCompany',
  [BusinessTypesIds.Individual]: 'Individual',
};

import {
  BusinessDetails,
  RelatedEntityTypesIds,
  LegalEntities,
} from 'src/types';

interface BusinessType {
  id: string;
  name: string;
  abbr: string;
}

export const getBusinessType = (
  businessTypes: BusinessType[],
  data: BusinessDetails,
  enableCorporateOnboardingFrontEnd: boolean
): BusinessType => {
  // [TODO]: As we're now using locale rather than country, we need to ensure we only
  // show CCA steps for CCA supported countries. E.g. Albania falls under OFX-US, so
  // selecting Albania and Private Company woud incorrectly show CCA steps.
  // Therefore I've implemented the hack below... If it's a CCA locale, but not a CCA country
  // force a non-CCA id. This is not ideal.
  /**
   * 23/01/2024: If the flag is on, it means not only US or GB are going to go through CCA
   * but also other countries that are OOJ. The flag is per country so it won't cover
   * every country falling under the legal entity, it needs to be enabled in the flag
   * in a by country case.
   */
  let businessTypeId = data.businessType;
  if (
    !enableCorporateOnboardingFrontEnd &&
    (data.locale === LegalEntities.GB || data.locale === LegalEntities.US)
  ) {
    if (!data.locale.includes(data.country))
      businessTypeId = RelatedEntityTypesIds.Trust;
  }
  return businessTypes.find((b) => b.id === businessTypeId);
};

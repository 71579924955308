import React, { Component, ComponentType, ReactElement } from 'react';
import Store from '../Store';

export default function withStore<P extends {}>(
  ComponentToWrap: ComponentType<P>
): ComponentType<P> {
  return class StoreWrapper extends Component<P> {
    public static displayName = 'WithStore';
    public render(): ReactElement {
      return (
        <Store.Consumer>
          {(storeAsProps) => {
            return <ComponentToWrap {...storeAsProps} {...(this.props as P)} />;
          }}
        </Store.Consumer>
      );
    }
  };
}

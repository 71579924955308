export default {
  '3949f7ed-c6fd-417f-94ea-a694e764c74d': {
    name: 'Sole Trader',
    abbr: 'st',
  },
  '634e1072-291c-4f0b-8e06-c49f2a3cba41': {
    name: 'Private Company',
    abbr: 'pc',
  },
  '92120555-d681-4703-874f-47f613bd5522': {
    name: 'Partnership',
    abbr: 'pa',
  },
  '6ca3ae8e-7377-4b01-82bd-ae9534a81193': {
    name: 'Trust',
    abbr: 'tr',
  },
  'b7440a9a-1831-4866-b7af-06b6da8e7f00': {
    name: 'NonProfit',
    abbr: 'np',
  },
  'fe59ebeb-6e81-4e5e-8aa2-4c1538578cbd': {
    name: 'PublicCompany',
    abbr: 'pb',
  },
  '4268e97f-7e79-42f7-8c8e-97cea3b3ec98': {
    name: 'Personal',
    abbr: 'pr',
  },
};

import { SvgIcon } from '@material-ui/core';

const ErrorIcon: React.FunctionComponent<{}> = (): React.ReactElement => (
  <SvgIcon width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
    <g
      id="Corp-Onboarding"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g
        id="XS----Private-Company---Step-1---User-Error"
        transform="translate(-56.000000, -415.000000)"
        fillRule="nonzero"
        stroke="#FFFFFF"
        strokeWidth="2"
      >
        <g
          id="Feedback/Error-Permanent-Feedback/Error-Dismissable"
          transform="translate(40.000000, 399.000000)"
        >
          <g id="Icon-Error" transform="translate(16.000000, 16.000000)">
            <g id="Icon/Alert">
              <path
                d="M9.3262338,22.6727807 C9.50031495,22.7162517 9.67603838,22.7555753 9.85329304,22.7906403 C10.5475936,22.9279888 11.2653873,23 12,23 C18.0751322,23 23,18.0751322 23,12 C23,5.92486775 18.0751322,1 12,1 C5.92486775,1 1,5.92486775 1,12 C1,15.0375661 2.23121694,17.7875661 4.22182541,19.7781746 M12,7 L12,13 M12.0001528,16.99 L12.0001528,17"
                id="Alert"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </SvgIcon>
);

export default ErrorIcon;

import { Box, Typography } from '@material-ui/core';
import NoJS from '@fox/components/Illustrations/NoJS';
import React, { FC } from 'react';
import { jsDisabledPageMessages } from 'src/i18n/jsDisabledMessages';
import theme from 'src/fox/Theme/MuiTheme';

const JsDisabled: FC = () => {
  const { header, subtext } = jsDisabledPageMessages;

  return (
    <noscript>
      <Box height="100vh" width="100%" display="flex" alignItems="center">
        <Box width="90%" maxWidth={theme.spacing(71)} margin="auto">
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            margin="auto"
            width="auto"
          >
            <NoJS />
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            paddingTop={2}
            margin="auto"
          >
            <Typography variant="h6">
              <b>{header}</b>
            </Typography>
          </Box>
          <Box paddingTop={1} margin="auto">
            <Typography variant="subtitle1" align="center">
              {subtext}
            </Typography>
          </Box>
        </Box>
      </Box>
    </noscript>
  );
};

export default JsDisabled;

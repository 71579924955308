import clsx from 'clsx';
import { makeStyles, Theme, withStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepConnector from '@material-ui/core/StepConnector';
import { StepIconProps } from '@material-ui/core/StepIcon';

interface StepperProps {
  steps: string[];
  activeStep: number;
}

const Connector = withStyles((theme) => ({
  alternativeLabel: {
    top: '10px',
    left: 'calc(-50% + 3px)',
    right: 'calc(50% + 3px)',
  },
  active: {
    '& $line': {
      background: `linear-gradient(45deg, ${theme.fox.colors.orange.s500.hex}, ${theme.fox.colors.orange.s600.hex})`,
    },
  },
  completed: {
    '& $line': {
      background: theme.fox.colors.orange.s500.hex,
    },
  },
  line: {
    height: '2px',
    backgroundColor: theme.fox.colors.neutral.s300.hex,
    border: 'none',
  },
}))(StepConnector);

const StyledStepLabel = withStyles((theme: Theme) => ({
  root: {
    '& .MuiStepLabel-label': {
      marginTop: theme.spacing(1),
      fontFamily: theme.fox.typeStyles.caption.fontFamily,
      fontSize: theme.fox.typeStyles.caption.fontSize,
      fontWeight: parseInt(theme.fox.typeStyles.caption.fontWeight),
      color: theme.fox.colors.neutral.s800.hex,
    },
    '& .MuiStepLabel-label.MuiStepLabel-active': {
      fontWeight: 'bold',
      color: theme.fox.colors.neutral.s900.hex,
    },
  },
}))(StepLabel);

const StepIconStyles = makeStyles((theme) => ({
  root: {
    color: theme.fox.colors.neutral.s300.hex,
    display: 'flex',
    height: '22px',
    alignItems: 'center',
  },
  active: {
    color: theme.fox.colors.orange.s500.hex,
  },
  circle: {
    width: '8px',
    height: '8px',
    zIndex: 1,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
  completed: {
    color: theme.fox.colors.orange.s500.hex,
    zIndex: 1,
    fontSize: '18px',
  },
  activeStep: {
    color: theme.fox.colors.orange.s600.hex,
    width: '12px',
    height: '12px',
  },
}));

const StepIconComponent: React.FC<StepIconProps> = ({
  active,
  completed,
}: StepIconProps) => {
  const classes = StepIconStyles({});

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      {completed ? (
        <div
          className={clsx(classes.completed, classes.circle, {
            [classes.activeStep]: active,
          })}
        />
      ) : (
        <div
          className={clsx(classes.circle, {
            [classes.activeStep]: active,
          })}
        />
      )}
    </div>
  );
};

function formatForDataTracking(value: string): string {
  return value.toLowerCase().replace(/ /g, '-');
}

const StyledStepper: React.FC<StepperProps> = ({
  steps,
  activeStep,
}: StepperProps) => {
  return (
    <Stepper
      alternativeLabel
      activeStep={activeStep}
      connector={<Connector />}
      data-tracking-id={`stepper-selected-${activeStep}`}
    >
      {steps.map((label) => (
        <Step
          key={label}
          data-tracking-id={'step-' + formatForDataTracking(label)}
        >
          <StyledStepLabel StepIconComponent={StepIconComponent}>
            {label}
          </StyledStepLabel>
        </Step>
      ))}
    </Stepper>
  );
};

export default StyledStepper;

import { PassfortWebhookEventTypes } from './WebsocketEvents/ComplianceEvents';

export const awsAppSyncRegion = 'ap-southeast-2';
export const authenticationType = 'AWS_LAMBDA';
// Once events are known and mapper ticket is ready MOVE THIS TO WEBEVENTS FILE
export enum Channel {
  COMPLIANCE = 'Compliance',
}
export const AllowedPassfortWebHooks = [
  PassfortWebhookEventTypes.COMPANY_DATA_CHECK,
  PassfortWebhookEventTypes.UBO_IDENTITY_CHECK,
  PassfortWebhookEventTypes.CORP_COMPANY_DETAIL_VERIFICATION,
  PassfortWebhookEventTypes.PRIMARY_USER_IDENTITY_CHECK,
  PassfortWebhookEventTypes.IDENTITY_CHECK_CORPORATE,
];

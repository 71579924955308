import {
  AllComplianceEvents,
  AllEVStatuses,
  IdentityCheck,
} from '../src/components/WebsocketHandler/WebsocketEvents/ComplianceEvents';

export interface AddressDetails {
  Floor?: string;
  Block?: string;
  Premise?: string;
  StreetDirection?: string;
  UnitType?: string;
  UnitNumber?: string;
  StreetNumber?: string;
  StreetName?: string;
  StreetType?: string;
  CitySuburb?: string;
  StateProvince?: string;
  Postcode?: string;
  Country: string;
}

export type AddressTypes = 'business' | 'trading' | 'home' | null;

export interface Shareholder {
  id?: string;
  firstName: string;
  middleName?: string;
  lastName: string;
  verified: boolean;
  dob: number;
  nationalIdentityNumber?: string;
  mobile: string;
  address: string;
  addressDetails: AddressDetails;
  email: string;
  percentageOwned: number;
  isPrimaryUser: boolean;
  companyMemberRoles: string[];
  jobTitle: string;
  ssn?: string;
  isDirector?: boolean;
  isEditable?: boolean;
  validState?: boolean;
  dateOfBirth?: string;
  evStatus?: AllEVStatuses;
}

export interface User {
  id?: string;
  username?: string;
  locale?: string;
  country: string;
  entityTypeId: string;
  firstName: string;
  lastName: string;
}

export interface UserDetail {
  firstName: string;
  lastName: string;
  middleName?: string;
  email: string;
  mobile?: string;
  dateOfBirth?: number;
  socialSecurityNumber?: string;
  jobTitle?: string;
  username?: string;
  currentAddress?: AddressDetails;
}

export interface LeadGenDetails {
  firstName: string;
  lastName: string;
  email: string;
  country: string;
  businessType: string;
  fromCurrency: string;
  toCurrency: string;
  transferRange: string;
  userAgreement: boolean;
  accountancyPractice: boolean;
}

export interface BusinessDetails extends LeadGenDetails {
  address?: AddressDetails;
  businessName?: string;
  businessNumber?: string;
  industry?: string;
  businessWebsite?: string;
  addressFormatUrl?: string;
  username?: string;
  password?: string;
  securityQuestion?: string;
  securityAnswer?: string;
  locale?: string;
  relatedEntity: boolean;
  relatedEntityType?: string;
  relatedEntityBusinessName?: string;
  relatedEntityBusinessNumber?: string;
  isGcaAccount?: boolean;
  currencies?: string[];
  accountPurpose?: string;
  marketplaces?: string[];
  paymentGateways?: string[];
  payerLocations?: string[];
  sendingFundsTo?: string[];
  sendingCurrencies?: string[];
  reasonsForPayment?: string[];
  otherReasonsForPayment?: string;
  paymentFrequency?: string;
  fundingCurrencies?: string[];
  roleInEntity?: string;
  businessRevenueGenerationMode?: string;
  fundsGeneratedByBusiness?: boolean;
  fundsGeneratedWay?: string;
  sendingFundsFromEntityOwnedAccount?: boolean;
  fundingAccountAndRelationship?: string;
  kycGeneralComments?: string;
}

export interface PaytronUserDetails {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  phoneNumber: string;
  userAgreement: boolean;
}

export enum PrimaryUserRole {
  shareholderOfficer = 'Primary User,Beneficial Owner,Controlling Person',
  shareHolder = 'Primary User,Beneficial Owner',
  officer = 'Primary User,Controlling Person',
  employee = 'Primary User',
}

export enum options {
  Yes = 'Yes',
  No = 'No',
}

export interface PrimaryUserForm {
  percentageOwned: string;
  ssn: string;
  mobile: string;
  companyMemberRole: PrimaryUserRole;
  termsAndConditions: boolean;
  website: string;
  industry: string;
  addressDetails?: AddressDetails;
}

export type CorpPrimaryUser = UserDetail & PrimaryUserForm;

export interface ApiState<T> {
  data: T | null;
  isError: boolean;
}

export enum RelatedEntityTypesIds {
  Trust = '6ca3ae8e-7377-4b01-82bd-ae9534a81193',
  Partnership = '92120555-d681-4703-874f-47f613bd5522',
  NonProfit = 'b7440a9a-1831-4866-b7af-06b6da8e7f00',
}

export interface I18n {
  locale: string;
  dateFormat: string;
  dateOfBirth: {
    label: string;
  };
  genericServerErrorText: string;
  docPopover: {
    docPopoverLink?: string;
    docPopoverCloseButtonText?: string;
    docPopoverHeading?: string;
    docPopoverBody1?: string;
    docPopoverBody2?: string;
  };
  registrationPage: {
    header: string;
    subHeader: string;
    referredByText: string;
    personalTile: { title: string; subTitle: string };
    businessTile: { title: string; subTitle: string };
    firstNameLabel: string;
    lastNameLabel: string;
    emailLabel: string;
    countryLabel: string;
    businessTypeLabel: string;
    currencyHeader: string;
    currencySubHeader: string;
    fromCurrencyLabel: string;
    toCurrencyLabel: string;
    transferRangeHeader: string;
    transferRange1: string;
    transferRange2: string;
    transferRange3: string;
    transferRange4: string;
    newTransferRange: {
      range1: string;
      range2: string;
      range3: string;
      range4: string;
      range5: string;
      range6: string;
      range7: string;
      range8: string;
    };
    userAgreement: {
      link: string;
      text: string;
      linkText: string;
    }[];
    accountancyPractice: {
      text: string;
      subTitle: string;
    };
  };
  businessDetailsPage: {
    securityAnswer: string;
    securityQuestion: string;
    username: string;
    password: string;
    businessNumber: string;
    createBusinessHeading: string;
    autoCompanySearch: string;
    registeredBusinessAddress: string;
    alternativeAddress: string;
    businessWebsite: string;
    businessIndustry: string;
    businessIndustryDivision: string;
    businessIndustryGroup: string;
    businessName: string;
    header: string;
    subHeader: string;
    aboutBusinessHeading: string;
    tradingAddress: string;
    relatedEntity?: {
      isRelatedEntityQuestion: string;
      isRelatedEntityOptions: { value: boolean; caption: string }[];
      entityTypeQuestion: string;
      entityTypeOptions: { id: RelatedEntityTypesIds; name: string }[];
      relatedEntityBusinessName: string;
      relatedEntityBusinessNumber: string;
    };
  };
  aboutPage: {
    header: string;
    subHeader: string;
    button: string;
    errorHeader: string;
    userAgreement: {
      description: string;
      agreementLabel: string;
      policyLabel: string;
      agreementLink: string;
      policyLink: string;
    };
    firstName: string;
    middleName: string;
    lastName: string;
    email: string;
    phone: string;
    mobile: string;
    address: string;
    jobTitle: string;
    percentageOwned: string;
    aboutYouHeading: string;
  };
  aboutPagePrompts?: {
    OptionalNationalIdentityNumberFieldPrompt?: {
      headerText: string;
      subHeaderText: string;
      proceedBtn: string;
      goBackBtn: string;
    };
  };
  digitalKycForm: {
    headerLabel: string;
    headerText: string;
    subHeaderLabel: string;
    subHeaderText: string;
    sendingFundsTo: string;
    sendingCurrencies: string;
    reasonsForPayments: string;
    otherReasonsForPayments: string;
    paymentFrequency: string;
    fundingCurrencies: string;
    roleInEntity: string;
    businessRevenueGenerationMode: string;
    fundsGeneratedByBusiness: string;
    fundsGeneratedWay: string;
    sendingFundsFromEntityOwnedAccount: string;
    fundingAccountAndRelationship: string;
    kycGeneralComments: string;
  };
  thankYouPage?: ThankYouPageMessagesType;
  shareholdersPage: ShareholdersPageMessagesType;
  uploadPage: {
    header: string;
    subHeader: string;
    businessDocuments: {
      heading: string;
      displayAsList: boolean;
      items: {
        description: string;
        listItems?: string[];
      }[];
    };
    personalDocuments: {
      heading: string;
      displayAsList: boolean;
      items: {
        description: string;
        listItems?: string[];
      }[];
      noPersonalDocumentText: string;
    };
  };
  addShareholdersBox: {
    topMessage: string;
    bottomMessage: string;
    bottomMessageSecondary?: string;
    buttonLabel: string;
  };
  shareholdersForm: {
    header: string;
    subHeader: string;
    firstNameLabel: string;
    lastNameLabel: string;
    emailLabel: string;
    mobileLabel: string;
    addressLabel: string;
    submitButtonLabel: string;
    discardButtonLabel: string;
    describePositionLabel: string;
    companyMemberRoles: {
      directorAndShareHolder: string;
      primaryUser: string;
      director: string;
      beneficialOwner: string;
      complexShareholderMessage: string;
    };
    jobTitleLabel: string;
    sharesOwnedLabel: string;
    manualAddressFieldSetTitle: string;
    addressSearch?: string;
  };
  shareholdersFormSpenta?: {
    header: string;
    subHeader: string;
    firstNameLabel: string;
    lastNameLabel: string;
    emailLabel: string;
    mobileLabel: string;
    addressLabel: string;
    addressLabelOptional: string;
    submitAddButtonLabel: string;
    submitUpdateButtonLabel: string;
    discardButtonLabel: string;
    describePositionLabel: string;
    companyMemberRoles: {
      directorAndShareHolder: string;
      primaryUser: string;
      director: string;
      beneficialOwner: string;
    };
    jobTitleLabel: string;
    sharesOwnedLabel: string;
    manualAddressFieldSetTitle: string;
    manualAddressFieldSetTitleOptional: string;
    addressSearch?: string;
    ssnTooltip?: string;
    ssn?: string;
    ssnOptional?: string;
    nationalIdentityNumber?: string;
    hkidOptional?: string;
    nricOptional?: string;
    dobOptional: string;
    optionalFieldsHelperAdd: string;
    optionalFieldsHelperUpdate: string;
  };
  manualAddress: {
    fieldSetTitle: string;
  };
  userDetailsCard: {
    verified: string;
    verifying: string;
    unableToVerify: string;
    primaryUser: string;
    dob: string;
    address: string;
    phone: string;
    notAvailable: string;
    email: string;
    sharesOwned: string;
    ssn?: string;
    nric?: string;
    hkid?: string;
    nationalIdentityNumber?: string;
  };
  userDetailForm: {
    header: string;
    firstNameLabel: string;
    lastNameLabel: string;
    emailLabel: string;
    mobileLabel: string;
    addressLabel: string;
    ssnLabel: string;
    ssnPopoverOpenButtonText: string;
    ssnPopoverCloseButtonText: string;
    ssnPopoverHeading: string;
    ssnPopoverBody: string;
    manualAddressFieldSetTitle: string;
  };
  validationMessages: ValidationMessagesType;
  primaryUserRoles: {
    shareholderOfficer: string;
    shareHolder: string;
    officer: string;
    employee: string;
  };
}

export interface SubText {
  text: string;
  highlight?: string;
}

export interface ThankYouPageMessagesType {
  header: (name: string) => string;
  subtext1: string;
  subtext2: string;
  subtext3: string;
  subtext4: string;
  personalDocuments: SubText[];
  personalDocumentsForPassfortOnboarding: SubText[];
  businessDocuments: SubText[];
}

export interface ShareholdersPageMessagesType {
  header: string;
  subHeader: string;
  topReviewMessage?: string;
  topInfoMessage?: {
    bold?: string;
    normal?: string;
  };
  bottomReviewMessage?: string;
  errorInUBOListDialog?: {
    headerText: string;
    subHeaderText: string;
    button: string;
  };
  confirmCheckUBOLaterDialog?: {
    headerText: string;
    subHeaderText: string;
    button: string;
    link: string;
  };
  errorInSendingUBOsDialog?: {
    headerText: string;
    subHeaderText: string;
    button: string;
  };
  optionalFieldsBeforeConfirmationUBO?: {
    headerText: string;
    subHeaderText: string;
    proceedBtn: string;
    goBackBtn: string;
  };
  buttonLabel: string;
  newOnboardingSpinner: newOnboardingLoaderMessagesType[];
  spentaErrorDialog: spentaErrorDialogType;
}

export interface newOnboardingLoaderMessagesType {
  firstLineMessage: string;
  secondLineMessage: string;
}

export interface spentaErrorDialogType {
  header: string;
  subHeader: string;
  button: string;
}

export interface JsDisabledPageMessagesType {
  header: string;
  subtext: string;
}

export interface BoxText {
  header: string;
  text: string;
}

export interface OnfidoStartPageMessagesType {
  header: string;
  subtext1: string;
  subtext2: string;
  subtext3: string;
  subtext4: string;
  webcamText: BoxText;
  disclaimer: {
    text: string;
    link: {
      text: string;
      url: string;
    };
    textEnd: string;
  };
}

export interface EVFailUploadPageMessagesType {
  header: (name: string) => string;
  subtext: string;
}

export interface VerificationMessagesType {
  OnfidoStartPage: OnfidoStartPageMessagesType;
  EvFailUploadPage: EVFailUploadPageMessagesType;
  personalDocuments: BoxText[];
  businessDocuments: BoxText[];
}

export interface ValidationMessagesType {
  globalRequired: string;
  fieldRequired: string;
  fieldInvalid: string;
  minFieldLength(minLength: number): string;
  maxFieldLength(maxLength: number): string;
  DOB: {
    minYears: string;
    maxYears: string;
    futureDate: string;
    invalidDate: string;
    fieldRequired: string;
  };
  firstname: {
    invalid: string;
    maxFieldLength(maxLength: number): string;
    fieldRequired: string;
  };
  middlename: {
    invalid: string;
    maxFieldLength(maxLength: number): string;
    fieldRequired: string;
  };
  lastname: {
    invalid: string;
    maxFieldLength(maxLength: number): string;
    fieldRequired: string;
  };
  email: {
    invalid: string;
    maxFieldLength(maxLength: number): string;
    exists: string;
    fieldRequired: string;
  };
  currency: {
    unsupportedFrom: string;
    unsupportedTo: string;
    currencyMatch: string;
    fieldRequired: string;
    invalidListFrom(labels: string[]): string;
    invalidListTo(labels: string[]): string;
  };
  transferRange: {
    fieldRequired: string;
  };
  business: {
    maxFieldLength(maxLength: number): string;
    fieldRequired: string;
  };
  businessType: {
    fieldRequired: string;
  };
  businessNumber: {
    maxFieldLength(maxLength: number): string;
    fieldRequired: string;
  };
  relatedEntity: {
    fieldRequired: string;
  };
  username: {
    exists: string;
    fieldRequired: string;
  };
  share: {
    minimum: string;
    spentaMinimum: string;
    maximum: string;
    fieldRequired: string;
  };
  position: {
    maxFieldLength(maxLength: number): string;
    fieldRequired: string;
  };
  ssn: {
    invalid: string;
    fieldRequired: string;
  };
  license: {
    invalid: string;
    maxFieldLength(maxLength: number): string;
    fieldRequired: string;
  };
  phone: {
    invalid: string;
    invalidResponse: string;
    fieldRequired: string;
  };
  address: {
    fieldRequired: string;
    buildingName: {
      invalid: string;
      maxFieldLength(maxLength: number): string;
    };
    unitNo: {
      invalid: string;
      maxFieldLength(maxLength: number): string;
    };
    streetNo: {
      invalid: string;
      maxFieldLength(maxLength: number): string;
      fieldRequired: string;
    };
    streetName: {
      invalid: string;
      maxFieldLength(maxLength: number): string;
      fieldRequired: string;
    };
    streetType: {
      fieldRequired: string;
    };
    state: {
      fieldRequired: string;
    };
    city: {
      invalid: string;
      maxFieldLength(maxLength: number): string;
      fieldRequired: string;
    };
    zip: {
      invalid: string;
      maxFieldLength(maxLength: number): string;
      fieldRequired: string;
    };
  };
  securityAnswer: {
    maxFieldLength(maxLength: number): string;
    fieldRequired: string;
  };
  country: {
    invalid(label: string): string;
    invalidList(labels: string[]): string;
    fieldRequired: string;
  };
  industry: {
    fieldRequired: string;
  };
  industryGroup: {
    fieldRequired: string;
  };
  password: {
    fieldRequired: string;
  };
  securityQuestion: {
    fieldRequired: string;
  };
}

export type PageStep =
  | '1' // Not sure... remove?
  | '9' // Not sure... remove?
  | '100' // NotStarted (for the Secure App)
  | 'CCANotStarted'
  | 'CCAStarted'
  | 'CCAShareholdersStarted'
  | 'CCADocsUploaded'; // Sets Backend to 'Online CCA Complete'

export interface Store {
  user: ApiState<User>;
  setUserData: (userData: User) => void;
  setUserError: () => void;
  userDetail: ApiState<UserDetail>;
  setUserDetailData: (userDetail: UserDetail) => void;
  setUserDetailError: () => void;
  businessDetails: ApiState<BusinessDetails>;
  setBusinessDetails: (businessDetails: BusinessDetails) => void;
  i18n?: I18n;
  setI18n: (i18nData: I18n) => void;
  sessionId: ApiState<string>;
  setSessionId: (guid: string) => void;
  showSystemErrorMessage: ApiState<boolean>;
  setShowSystemErrorMessage: (showSystemErrorMessage: boolean) => void;
  isPassfortMigrated: boolean;
  setPassfortMigrationStatus: (isMigratedUser: boolean) => void;
  websocketEvents: IWebsocketEvents;
  setWebsocketEvents: (newEvents: IWebsocketEvents) => void;
  paytronUserDetails: PaytronUserDetails;
  setPaytronUserDetails: (newPaytronUserDeatils: PaytronUserDetails) => void;
}

export interface GtmProps {
  gtmId: string;
}

export interface OnfidoProps {
  id: string;
  firstName: string;
  middleName: string;
  lastName: string;
  email: string;
  username: string;
  entityTypeId: string;
  brand: string;
  dob: string;
  country: string;
}

export type FormTypes = 'aboutBusiness' | 'aboutUser' | 'shareholderDetails';

export enum RegoTypes {
  Business = 'business',
  Personal = 'personal',
}

export enum LegalEntities {
  AU = 'OFX-AU',
  CA = 'OFX-CA',
  HK = 'OFX-HK',
  NZ = 'OFX-NZ',
  SG = 'OFX-SG',
  GB = 'OFX-GB',
  US = 'OFX-US',
  IE = 'OFX-IE',
}

export enum BrandsMapping {
  'OzForex' = 'OFX-AU',
  'UKForex' = 'OFX-GB',
  'HKForex' = 'OFX-HK',
  'USForex' = 'OFX-US',
  'SingForex' = 'OFX-SG',
  'CanadianForex' = 'OFX-CA',
  'NZForex' = 'OFX-NZ',
}

export enum LegalShortNames {
  AU = 'OzForex',
  CA = 'CanadianForex',
  US = 'USForex',
  EU = 'IEOFX',
  HK = 'HKForex',
  SG = 'SingForex',
}

export enum entityTypeAbbreviations {
  'pc',
  'st',
  'np',
  'pa',
  'pb',
  'tr',
}

export enum BusinessTypesIds {
  PrivateCompany = '634e1072-291c-4f0b-8e06-c49f2a3cba41',
  SoleTrader = '3949f7ed-c6fd-417f-94ea-a694e764c74d',
  Partnership = '92120555-d681-4703-874f-47f613bd5522',
  Trust = '6ca3ae8e-7377-4b01-82bd-ae9534a81193',
  NonProfit = 'b7440a9a-1831-4866-b7af-06b6da8e7f00',
  PublicCompany = 'fe59ebeb-6e81-4e5e-8aa2-4c1538578cbd',
  Individual = '4268e97f-7e79-42f7-8c8e-97cea3b3ec98',
}

export enum BusinessTypesAbbreviations {
  withoutCca = 'withoutCca',
  soleTraderWithCca = 'soleTraderWithCca',
  privateCompanyWithCca = 'privateCompanyWithCca',
  nonProfitWithCca = 'nonProfitWithCca',
  partnershipWithCca = 'partnershipWithCca',
  publicCompanyWithCca = 'publicCompanyWithCca',
  trustWithCca = 'trustWithCca',
}

export enum VerifiedStatus {
  unverified = 'Unverified',
  verified = 'Verified',
}

export enum WebsocketOperationalStatus {
  Initializing = 'Initializing',
  Connected = 'Connected',
  Disconnected = 'Disconnected',
}

export interface IWebsocketEvents {
  operationalStatus: WebsocketOperationalStatus;
  complianceEvent: AllComplianceEvents;
}

export interface ICorpUsers {
  identityCheck: IdentityCheck;
  corpEvCheck: AllEVStatuses;
  identityCheckOnfido: string;
  passfortProfileId: string;
}

export interface ICorporateStatus {
  companyDataCheckResult: string;
  corpUsers: ICorpUsers[];
  ubos: ICorporateStatusUbo[];
  companyDetailVerificationResult: string;
}

export interface ICorporateStatusUbo {
  identityCheck: string;
  passfortProfileId: string;
}

const CrossLarge: React.FunctionComponent<{}> = (): React.ReactElement => (
  <svg
    width="16px"
    height="16px"
    viewBox="0 0 16 16"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      id="Corp-Onboarding"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g
        id="LG---Private-Company---Step-1---About-you"
        transform="translate(-1216.000000, -40.000000)"
        fillRule="nonzero"
        stroke="#3C3C3B"
        strokeWidth="2"
      >
        <g id="Nav" transform="translate(0.000000, 0.000000)">
          <g
            id="Input/Button/Large/Secondary-IconOnly/Default"
            transform="translate(1200.000000, 24.000000)"
          >
            <g
              id="Assets/Icon/Cross"
              transform="translate(12.000000, 12.000000)"
            >
              <g id="Icon/Cross">
                <path
                  d="M19,5 L12,12 L19,19 M5,19 L12,12 L5,5"
                  id="Cross"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default CrossLarge;

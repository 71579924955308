import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import CrossLarge from '../../fox/components/Icons/CrossLarge';

interface CloseButtonProps {
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  dataTrackingId?: string;
}

const useStyles = makeStyles((theme: Theme) => {
  const { colors } = theme.fox;
  return {
    root: {
      backgroundColor: colors.neutral.s200.hex,
      width: '48px',
      height: '48px',
    },
  };
});

const CloseButton = ({
  onClick,
  dataTrackingId = 'button-close',
}: CloseButtonProps): React.ReactElement => {
  const classes = useStyles({});

  return (
    <IconButton
      size="medium"
      className={classes.root}
      onClick={onClick}
      data-tracking-id={dataTrackingId}
      aria-label="Exit"
    >
      <CrossLarge />
    </IconButton>
  );
};

export default CloseButton;

import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import theme from '../../Theme/Theme';
import { FoxTypeStyleNames } from '../../Types';

export interface Props {
  variant: FoxTypeStyleNames;
  textAlign?: 'left' | 'right' | 'center';
  lineHeight?: string;
  fontWeight?:
    | number
    | '-moz-initial'
    | 'inherit'
    | 'initial'
    | 'revert'
    | 'unset'
    | 'normal'
    | 'bold'
    | 'bolder'
    | 'lighter'
    | undefined;
  textTransform?:
    | '-moz-initial'
    | 'inherit'
    | 'initial'
    | 'revert'
    | 'unset'
    | 'none'
    | 'full-width'
    | 'capitalize'
    | 'full-size-kana'
    | 'lowercase'
    | 'uppercase'
    | undefined;
  color?: string;
  noWrap?: boolean;
  // https://github.com/microsoft/TypeScript/issues/6471#issuecomment-171456118
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children?: any;
  component?: React.ElementType<React.HTMLAttributes<HTMLElement>>;
  whiteSpace?:
    | '-moz-initial'
    | 'inherit'
    | 'initial'
    | 'revert'
    | 'unset'
    | 'normal'
    | 'pre'
    | 'nowrap'
    | '-moz-pre-wrap'
    | 'break-spaces'
    | 'pre-line'
    | 'pre-wrap';
}

const StyledTypography = ({
  children,
  variant,
  textAlign = 'left',
  fontWeight = 400,
  lineHeight,
  color,
  component = 'p',
  noWrap,
  textTransform,
  whiteSpace = 'normal',
}: Props): React.ReactElement => {
  const classes = makeStyles({
    root: {
      fontFamily: theme.typeStyles[variant].fontFamily,
      fontSize: theme.typeStyles[variant].fontSize,
      lineHeight: lineHeight || theme.typeStyles[variant].lineHeight,
      color: color || theme.typeStyles[variant].color,
      letterSpacing: theme.typeStyles[variant].letterSpacing,
      fontStyle: theme.typeStyles[variant].fontStyle,
      textTransform,
      fontWeight,
      textAlign,
      whiteSpace,
    },
  })({});

  return (
    // @ts-ignore - "Type instantiation is excessively deep and possibly infinite.ts"
    // Don't know how to fix this to make TS happy.
    <Typography component={component} className={classes.root} noWrap={noWrap}>
      {children}
    </Typography>
  );
};

export default StyledTypography;

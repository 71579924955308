export const SubscribeToChannel = `
subscription subToChannel ($name: String!, $userId: String!){
    subscribe(name: $name, UserID: $userId) {
        name
        UserID
        type
        data
    }
}
`;

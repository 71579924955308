/* eslint-disable @typescript-eslint/no-unsafe-argument */
import getConfig from 'next/config';
import * as Sentry from '@sentry/browser';
import { serializeError } from 'serialize-error';

const filterData = (data: object): object => {
  const sensitive = [
    'Email',
    'Username',
    'Password',
    'SecurityQuestion',
    'SecurityAnswer',
    'FirstName',
    'LastName',
    'PrimaryContactNumber',
    'RegistrationChannelId',
    'JobTitle',
    'Dob',
  ];

  if (typeof data === 'object') {
    sensitive.map((s) => {
      if (data[s]) delete data[s];
    });
  }

  return data;
};

const getRequestData = (
  api: string,
  data: { Country?: string; EntityType?: string }
): object => {
  const {
    publicRuntimeConfig: { apiBaseUrl },
  } = getConfig() as NextConfigTypes;

  switch (api) {
    case `${apiBaseUrl}/User.ApiService/User/Business`:
      return {
        country: data.Country,
        businessType: data.EntityType,
      };
    default:
      JSON.stringify(filterData(data) || {});
  }
};

const errorMessage = (
  error: { Message: boolean; ErrorCode: string; message: string },
  api: string
): string => {
  switch (true) {
    case error.Message:
      return `[${error.ErrorCode}] ${error.Message.toString()}`;
    case error.message && api === 'onfido':
      return `[Onfido] - Error Message: ${error.message}`;
    case !error.message && api === 'onfido':
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      return `[Onfido] - Unknown Error Message: ${error}`;
    default:
      return JSON.stringify(serializeError(error).message);
  }
};

export const sentryLog = (url: string, data: object, error: any): void => {
  const message = errorMessage(error, url);

  Sentry.withScope((scope) => {
    scope.setTag('api', url.split('?')[0]);
    scope.setExtra('isBrowser', true);
    scope.setExtra('request', getRequestData(url, data));
    scope.setExtra('response', JSON.stringify(error));
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    scope.setFingerprint([error.ErrorCode || '', url]);

    Sentry.captureMessage(message);
    Sentry.captureException(error);
  });
};

import { SvgIcon } from '@material-ui/core';

const orange = '#F59E38';

interface Props {
  color?: string;
}
const InfoIcon: React.FunctionComponent<Props> = ({
  color = orange,
}: Props): React.ReactElement => (
  <SvgIcon width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
    <g
      id="Asset-Artboard-Page"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g id="Icon-👈" fillRule="nonzero" stroke={color} strokeWidth="2">
        <g id="Icon/Info">
          <path
            d="M9.3262338,22.6727807 C9.50031495,22.7162517 9.67603838,22.7555753 9.85329304,22.7906403 C10.5475936,22.9279888 11.2653873,23 12,23 C18.0751322,23 23,18.0751322 23,12 C23,5.92486775 18.0751322,1 12,1 C5.92486775,1 1,5.92486775 1,12 C1,15.0375661 2.23121694,17.7875661 4.22182541,19.7781746 M12,11 L12,17 L12,11 Z M12.0001528,6.99 L12.0001528,7 L12.0001528,6.99 Z"
            id="Info"
          ></path>
        </g>
      </g>
    </g>
  </SvgIcon>
);

export default InfoIcon;

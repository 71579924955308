import moment from 'moment';
import jsCookie from 'js-cookie';
import businessTypeMapper from 'src/util/paytronUtil/businessTypeMapper';

export const cookies = {
  pid: 'pid',
  ssid: 'ssid',
  cid: 'cid',
  PARTNER: 'PARTNER',
  CID: 'CID',
  SSID: 'SSID',
  region: 'region',
  DOCSENT: 'docsent',
  ofxf: 'ofxf',
  // Paytron
  referralCode: 'referralCode',
  businessType: 'businessType',
};

const defaultRegion = 'en-AU';

const cookieDate = new Date(moment().add(1, 'years').toDate());
const oneMonthCookieDate = new Date(moment().add(1, 'months').toDate());

export const checkCookie = (name: string): boolean => {
  return !!jsCookie.get(name);
};

export const createDomainCookie = (
  key: string,
  value: string,
  expires = cookieDate
): void => {
  document.cookie = `${key}=${value}; expires=${expires}; path=/; domain=.ofx.com`;
};

export const getDomainCookie = (names: string[]): number | null => {
  for (let itr = 0; itr < names.length; itr++) {
    const value = jsCookie.get(names[itr]);

    if (value) {
      return parseInt(value);
    }
  }

  return null;
};

export const getDomainCookieAsString = (names: string[]): string | null => {
  for (let itr = 0; itr < names.length; itr++) {
    const value = jsCookie.get(names[itr]);

    if (value) {
      return value;
    }
  }
  return null;
};

export const setPARTNERCookie = (pid: string): void => {
  createDomainCookie(cookies.PARTNER, pid, oneMonthCookieDate);
};

export const setCIDCookie = (cid: string): void => {
  if (!(checkCookie(cookies.cid) || checkCookie(cookies.CID))) {
    createDomainCookie(cookies.CID, cid, cookieDate);
  }
};

export const setPaytronBusinessType = (businessType: string): void => {
  const mappedBusinesTypeString = businessTypeMapper[businessType];
  createDomainCookie(cookies.businessType, mappedBusinesTypeString, cookieDate);
};

export const setSSIDCookie = (ssid: string): void => {
  if (!(checkCookie(cookies.ssid) || checkCookie(cookies.SSID))) {
    createDomainCookie(cookies.SSID, ssid, cookieDate);
  }
};

export const setOfxFCookie = (ofxf: string): void => {
  createDomainCookie(cookies.ofxf, ofxf, cookieDate);
};

export const setReferralCodeCookie = (referralCode: string): void => {
  createDomainCookie(cookies.referralCode, referralCode, oneMonthCookieDate);
};

export const expireReferralCodeCookie = (): void => {
  document.cookie = `${cookies.referralCode}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=.ofx.com`;
};

interface Region {
  locale: string;
  region: string;
}

export const getRegionCookie = (): Region => {
  const value = jsCookie.get(cookies.region) || defaultRegion;
  const [locale, region] = value.split('-');
  return { locale, region };
};

import { makeStyles, Theme } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '../../fox/components/Typography';
import Button from '../../fox/components/Button';

const useStyles = makeStyles((theme: Theme) => {
  const { typeStyles } = theme.fox;

  return {
    root: {
      '& .MuiDialog-paper': {
        padding: `${theme.spacing(6)}px ${theme.spacing(2)}px`,
        margin: 0,
        width: '100vw',
        minHeight: '100vh',
        [theme.breakpoints.up('sm')]: {
          maxWidth: '328px',
          minHeight: 'initial',
          padding: `${theme.spacing(4)}px`,
        },
      },
    },
    dialogHeader: {
      fontFamily: typeStyles.small.fontFamily,
      fontSize: typeStyles.small.fontSize,
      fontWeight: 600,
      letterSpacing: 0.25,
      textAlign: 'center',
    },
    dialogBody: {
      fontFamily: typeStyles.body3.fontFamily,
      fontSize: typeStyles.body3.fontSize,
      letterSpacing: 0.25,
      textAlign: 'center',
      lineHeight: '24px',
    },
  };
});

interface Props {
  open: boolean;
  handleClose?: React.ReactEventHandler<{}>;
  handleLeave?: React.ReactEventHandler<{}>;
}
const StyledDialog: React.FunctionComponent<Props> = ({
  open,
  handleClose,
  handleLeave,
}: Props): React.ReactElement => {
  const classes = useStyles({});

  return (
    <Dialog
      disableEscapeKeyDown
      aria-labelledby="dialog"
      className={classes.root}
      open={open}
      onClose={handleClose}
    >
      <Box
        display={'flex'}
        flexDirection={'column'}
        justifyContent="center"
        alignItems="center"
      >
        <Box marginBottom={1} className={classes.dialogHeader}>
          Are you sure you want to leave this process?
        </Box>
        <Box marginBottom={4} className={classes.dialogBody}>
          Any information that you have not submitted or uploaded will not be
          saved.
        </Box>
        <Grid container spacing={3} justifyContent="center">
          <Grid item sm={6}>
            <Button
              trackingId="button-dialogCancel"
              onClick={handleClose}
              variant={'ghost'}
              fullWidth={true}
            >
              CANCEL
            </Button>
          </Grid>
          <Grid item sm={6}>
            <Button
              trackingId="button-dialogLeave"
              onClick={handleLeave}
              variant={'filled'}
              fullWidth={true}
            >
              <Typography variant="button2" lineHeight="20px">
                YES, LEAVE
              </Typography>
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Dialog>
  );
};

export default StyledDialog;

/*
 * TODO Might be nice to implement Sentry report feedback for our users:
 * https://docs.sentry.io/platforms/javascript/react/
 */
import React, { Component } from 'react';
import * as sentry from '@sentry/browser';
import getConfig from 'next/config';
import Alert from '@fox/components/Alert';
import { useRumError } from '@components/RumComponentContext';

const {
  publicRuntimeConfig: { sentryUrl },
} = getConfig();

interface State {
  hasError: boolean;
}

class PageErrorBoundary extends Component<{}, State> {
  public static displayName = 'PageErrorBoundary';

  public static getDerivedStateFromError(): State {
    return { hasError: true };
  }

  public constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  public componentDidCatch(error, errorInfo): void {
    useRumError();

    sentryUrl &&
      sentry.withScope((scope) => {
        scope.setExtras(errorInfo);
        sentry.captureException(error);
      });
  }

  public render(): React.ReactNode {
    if (this.state.hasError) {
      // TODO change Error UX https://ofx.atlassian.net/browse/DXP-1767
      return (
        <Alert
          severity="error"
          message="Sorry, something went wrong. Please try again."
        />
      );
    }

    return this.props.children;
  }
}

export default PageErrorBoundary;

import React, { useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Logo from '@components/Logo';
import Stepper from '../PageStepper';
import CloseButton from '../CloseButton';
import ConfirmationDialog from '../ConfirmationDialog';
import { useRouter } from 'next/router';

const useStyles = makeStyles((theme: Theme) => ({
  logoContainer: {
    height: 'auto',
    width: '87px',
    position: 'relative',
    left: `${theme.spacing(3)}px`,
    lineHeight: 0,
  },
  stepperContainer: {
    width: '100%',
    maxWidth: '900px',
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'initial',
    },
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: `${theme.fox.colors.neutral.s0.hex}`,
    borderBottom: `1px solid ${theme.fox.colors.neutral.s200.hex}`,
    minHeight: '96px',
  },
  closeButtonContainer: {
    width: '48px',
    marginRight: `${theme.spacing(4)}px`,
  },
}));

const Header = (): React.ReactElement => {
  const router = useRouter();
  const classes = useStyles({});
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const isRegistration: boolean = router.asPath.includes('/registration');
  const leaveTargetUrl: string = isRegistration
    ? 'https://ofx.com'
    : '/redirectToSecure';

  return (
    <>
      <div id="header" className={classes.headerContainer}>
        <div className={classes.logoContainer}>
          <Logo />
        </div>
        <div className={classes.stepperContainer}>
          <Stepper />
        </div>
        <div className={classes.closeButtonContainer}>
          <CloseButton
            onClick={(): void => setIsDialogOpen(true)}
            dataTrackingId="button-exit"
          />
        </div>
      </div>
      <ConfirmationDialog
        open={isDialogOpen}
        handleClose={() => setIsDialogOpen(false)}
        handleLeave={() => (location.href = leaveTargetUrl)}
      />
    </>
  );
};

export default Header;

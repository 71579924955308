import React, { ReactElement } from 'react';
import Alert from '@material-ui/lab/Alert';
import Box from '@material-ui/core/Box';
import InfoIcon from '@fox/components/Icons/InfoIcon/InfoIcon';
import ErrorIcon from '@fox/components/Icons/ErrorIcon/ErrorIcon';
import WarningIcon from '@fox/components/Icons/WarningIcon/WarningIcon';
import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => {
  const { colors, typeStyles } = theme.fox;
  return {
    root: {
      fontFamily: typeStyles.body3.fontFamily,
      fontSize: typeStyles.body3.fontSize,
      fontWeight: 'bold',
      minHeight: '56px',
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      boxShadow: '0 8px 12px 0 rgba(0,0,0,0.04)',
      whiteSpace: 'pre-line',
    },
    error: {
      backgroundColor: colors.red.s500.hex,
      color: colors.neutral.s0.hex,
      boxShadow: '0 4px 24px -8px rgba(236,0,38,0.48)',
    },
    warning: {
      backgroundColor: colors.red.s100.hex,
      color: colors.neutral.s900.hex,
      border: `1px solid ${colors.red.s200.hex}`,
      borderLeft: `4px solid ${colors.red.s500.hex}`,
    },
    info: {
      backgroundColor: colors.orange.s100.hex,
      color: colors.neutral.s900.hex,
      border: `1px solid ${colors.orange.s200.hex}`,
      borderLeft: `4px solid ${colors.orange.s500.hex}`,
    },
  };
});

interface AlertProps {
  severity: 'error' | 'warning' | 'info' | 'success';
  message: string;
}

const alertIcons = {
  error: <ErrorIcon />,
  warning: <WarningIcon />,
  info: <InfoIcon />,
};

const AlertMessage = ({
  severity,
  message,
}: AlertProps): ReactElement<AlertProps> => {
  const classes = useStyles({});

  return (
    <Box marginTop={2} marginBottom={2}>
      <Alert
        icon={alertIcons[severity]}
        severity={severity}
        className={`${classes.root} ${classes[severity]}`}
      >
        {message}
      </Alert>
    </Box>
  );
};

export default AlertMessage;

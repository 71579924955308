import { sentryLog } from 'src/util/sentryUtil';

export const ldIdentify = async (client, data): Promise<void> => {
  try {
    await client.waitForInitialization();
    client.identify({
      key: 'cae032c0-1979-11eb-83ae-5bec57c604f2',
      anonymous: true,
      ...data,
    });
  } catch (err) {
    sentryLog('LaunchDarkly init/identify', {}, err);
  }
};

// @ts-nocheck
import React, { useState, useEffect } from 'react';
import { datadogRum } from '@datadog/browser-rum';
import Router from 'next/router';
import getConfig from 'next/config';
import { AppProps } from 'next/app';
import Head from 'next/head';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import * as Sentry from '@sentry/node';
import { BrowserTracing } from '@sentry/browser';
import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ApolloProvider } from '@apollo/client';
import theme from '../src/fox/Theme/MuiTheme';
import Layout from '@components/Layout';
import StoreProvider from '@components/Store/StoreProvider/StoreProvider';
import PageErrorBoundary from '@components/PageErrorBoundary/PageErrorBoundary';
import { RumComponentContextProvider } from '@components/RumComponentContext';
import JsDisabled from '../src/pages/jsdisabled';
import { useApollo } from '../src/util/apolloClient';
import { WebsocketHandlerWithStore as WebsocketHandler } from '@components/WebsocketHandler/WebsocketHandler';
import '@ofx-com/fox.design-tokens/design-tokens.css';

const {
  publicRuntimeConfig: {
    sentryUrl,
    sentryEnvironment,
    launchDarklyKey,
    datadog,
    appVersion,
  },
} = getConfig() as NextConfigTypes;

if (sentryUrl) {
  Sentry.init({
    dsn: sentryUrl,
    environment: sentryEnvironment,
    ignoreErrors: ['Non-Error exception captured'],
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
  });
}

if (datadog) {
  datadogRum.init({
    applicationId: 'a383c92c-d9c8-4751-a191-d4984a9a57f9',
    clientToken: datadog.clientToken,
    site: 'datadoghq.com',
    service: 'helios',
    env: datadog.environment,
    version: appVersion,
    sampleRate: 100,
    sessionReplaySampleRate: 20, // if not included, the default is 100
    trackResources: true,
    trackInteractions: true,
    actionNameAttribute: 'data-tracking-id',
  });

  if (typeof window !== 'undefined') {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
    window.addEventListener(
      'userAnalyticsEvent',
      (event: CustomEvent<{ eventName: string }>) => {
        datadogRum.addAction(`ONFIDO - ${event.detail.eventName}`, {
          onfidoEvent: event.detail,
        });
      },
      false
    );
  }
}

const App: React.FC<AppProps> = (props: AppProps) => {
  const [jsEnabled, setJsEnabled] = useState(false);
  const { Component, pageProps } = props;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const err = (props as any).err;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const apolloClient = useApollo((pageProps as any).initialApolloState);
  // Workaround for https://github.com/zeit/next.js/issues/8592
  const modifiedPageProps = { ...pageProps, err };

  const loadOnfidoStyles = (): void => {
    if (Router.asPath === '/verification/start') {
      require('../public/static/css/onfido.css');
    }
  };

  useEffect(() => {
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles) {
      jssStyles.parentNode.removeChild(jssStyles);
    }
    setJsEnabled(true);
  }, []);

  useEffect(() => {
    loadOnfidoStyles();
  });

  return (
    <ApolloProvider client={apolloClient}>
      <ThemeProvider theme={theme}>
        <RumComponentContextProvider componentName="AppComponent">
          <CssBaseline />
          <PageErrorBoundary>
            <StoreProvider>
              <WebsocketHandler />
              <Layout jsEnabled={jsEnabled}>
                {jsEnabled && (
                  <>
                    <Head>
                      <meta
                        name="viewport"
                        content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no"
                      />
                    </Head>
                    <Component {...modifiedPageProps} />
                  </>
                )}
                <JsDisabled />
              </Layout>
            </StoreProvider>
          </PageErrorBoundary>
        </RumComponentContextProvider>
      </ThemeProvider>
    </ApolloProvider>
  );
};

export default withLDProvider({
  clientSideID: launchDarklyKey,
})(App);

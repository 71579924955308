import { PageStep, entityTypeAbbreviations } from '../types';
import {
  businessDetails,
  aboutYou,
  digitalKyc,
  ownerDetails,
  uploadDocuments,
  ccaSoleTraderAboutYou,
  ccaSoleTraderUploadDocuments,
  ccaNonProfitAboutYou,
  ccaNonProfitUploadDocuments,
  ccaPartnershipAboutYou,
  ccaPartnershipUploadDocuments,
  ccaPublicCompanyAboutYou,
  ccaPublicCompanyUploadDocuments,
  ccaTrustAboutYou,
  ccaTrustUploadDocuments,
  ccaPrivateCompanyAboutYou,
  ccaPrivateCompanyOwnerDetails,
  ccaPrivateCompanyUploadDocuments,
  PageConfig,
  businessDetailsToDigitalKyc,
} from './pages';

export interface StepConfig {
  allowedStatuses: PageStep[];
  pageName?: string;
  nextPagePath?: string;
  nextPageName?: string;
  nextStatus?: PageStep;
  stepperLabel:
    | 'Business details'
    | 'About your business needs'
    | 'About you'
    | 'Shareholders details'
    | 'Upload documents'
    | null;
}

export interface PagesConfig {
  pages: {
    [path: string]: StepConfig;
  };
}

export const registrationRoot = '/registration';

export const businessPath = `${registrationRoot}/business`;

const withoutCca: PagesConfig = {
  pages: {
    [businessDetails.path]: {
      ...businessDetails.config,
      allowedStatuses: [],
    },
    [aboutYou.path]: {
      ...aboutYou.config,
      allowedStatuses: [],
    },
  },
};

const withoutCcaWithDigitalKyc: PagesConfig = {
  pages: {
    [businessDetailsToDigitalKyc.path]: {
      ...businessDetailsToDigitalKyc.config,
      allowedStatuses: [],
    },
    [digitalKyc.path]: {
      ...digitalKyc.config,
      allowedStatuses: [],
    },
    [aboutYou.path]: {
      ...aboutYou.config,
      allowedStatuses: [],
    },
  },
};

const getWithCcaDigitalKycDefault = (ccaAboutYou: PageConfig): PagesConfig => {
  return {
    pages: {
      [businessDetailsToDigitalKyc.path]: businessDetailsToDigitalKyc.config,
      [digitalKyc.path]: digitalKyc.config,
      [aboutYou.path]: ccaAboutYou.config,
      [uploadDocuments.path]: uploadDocuments.config,
    },
  };
};

const getWithCcaDefault = (ccaAboutYou: PageConfig): PagesConfig => {
  return {
    pages: {
      [businessDetails.path]: businessDetails.config,
      [aboutYou.path]: ccaAboutYou.config,
      [uploadDocuments.path]: uploadDocuments.config,
    },
  };
};

const soleTraderWithCca: PagesConfig = getWithCcaDefault(ccaSoleTraderAboutYou);

const soleTraderWithCcaWithDigitalKyc: PagesConfig =
  getWithCcaDigitalKycDefault(ccaSoleTraderAboutYou);

const nonProfitWithCca: PagesConfig = getWithCcaDefault(ccaNonProfitAboutYou);

const nonProfitWithCcaWithDigitalKyc: PagesConfig =
  getWithCcaDigitalKycDefault(ccaNonProfitAboutYou);

const partnershipWithCca: PagesConfig = getWithCcaDefault(
  ccaPartnershipAboutYou
);

const partnershipWithCcaWithDigitalKyc: PagesConfig =
  getWithCcaDigitalKycDefault(ccaPartnershipAboutYou);

const publicCompanyWithCca: PagesConfig = getWithCcaDefault(
  ccaPublicCompanyAboutYou
);

const publicCompanyWithCcaWithDigitalKyc: PagesConfig =
  getWithCcaDigitalKycDefault(ccaPublicCompanyAboutYou);

const trustWithCca: PagesConfig = getWithCcaDefault(ccaTrustAboutYou);

const trustWithCcaWithDigitalKyc: PagesConfig =
  getWithCcaDigitalKycDefault(ccaTrustAboutYou);

const privateCompanyWithCca: PagesConfig = {
  pages: {
    [businessDetails.path]: businessDetails.config,
    [aboutYou.path]: ccaPrivateCompanyAboutYou.config,
    [ownerDetails.path]: ownerDetails.config,
    [uploadDocuments.path]: uploadDocuments.config,
  },
};

const privateCompanyWithCcaWithDigitalKyc: PagesConfig = {
  pages: {
    [businessDetailsToDigitalKyc.path]: businessDetailsToDigitalKyc.config,
    [digitalKyc.path]: digitalKyc.config,
    [aboutYou.path]: ccaPrivateCompanyAboutYou.config,
    [ownerDetails.path]: ownerDetails.config,
    [uploadDocuments.path]: uploadDocuments.config,
  },
};

const getDefaultConfig = (
  ccaAboutYou: PageConfig,
  ccaUploadDocuments: PageConfig
): PagesConfig => {
  return {
    pages: {
      [businessDetails.path]: businessDetails.config,
      [ccaAboutYou.path]: ccaAboutYou.config,
      [ccaUploadDocuments.path]: ccaUploadDocuments.config,
    },
  };
};

const getDefaultDigitalKycConfig = (
  ccaAboutYou: PageConfig,
  ccaUploadDocuments: PageConfig
): PagesConfig => {
  return {
    pages: {
      [businessDetailsToDigitalKyc.path]: businessDetailsToDigitalKyc.config,
      [digitalKyc.path]: digitalKyc.config,
      [ccaAboutYou.path]: ccaAboutYou.config,
      [ccaUploadDocuments.path]: ccaUploadDocuments.config,
    },
  };
};

const soleTraderConfigWithDigitalKyc: PagesConfig = getDefaultDigitalKycConfig(
  ccaSoleTraderAboutYou,
  ccaSoleTraderUploadDocuments
);

const soleTraderConfig: PagesConfig = getDefaultConfig(
  ccaSoleTraderAboutYou,
  ccaSoleTraderUploadDocuments
);

const nonProfitConfig: PagesConfig = getDefaultConfig(
  ccaNonProfitAboutYou,
  ccaNonProfitUploadDocuments
);

const nonProfitConfigWithDigitalKyc: PagesConfig = getDefaultDigitalKycConfig(
  ccaNonProfitAboutYou,
  ccaNonProfitUploadDocuments
);

const partnershipConfig: PagesConfig = getDefaultConfig(
  ccaPartnershipAboutYou,
  ccaPartnershipUploadDocuments
);

const partnershipConfigWithDigitalKyc: PagesConfig = getDefaultDigitalKycConfig(
  ccaPartnershipAboutYou,
  ccaPartnershipUploadDocuments
);

const publicCompanyConfig: PagesConfig = getDefaultConfig(
  ccaPublicCompanyAboutYou,
  ccaPublicCompanyUploadDocuments
);

const publicCompanyConfigWithDigitalKyc: PagesConfig =
  getDefaultDigitalKycConfig(
    ccaPublicCompanyAboutYou,
    ccaPublicCompanyUploadDocuments
  );

const trustConfig: PagesConfig = getDefaultConfig(
  ccaTrustAboutYou,
  ccaTrustUploadDocuments
);

const trustConfigWithDigitalKyc: PagesConfig = getDefaultDigitalKycConfig(
  ccaTrustAboutYou,
  ccaTrustUploadDocuments
);

const privateCompanyConfigWithDigitalKyc: PagesConfig = {
  pages: {
    [businessDetailsToDigitalKyc.path]: businessDetailsToDigitalKyc.config,
    [digitalKyc.path]: digitalKyc.config,
    [ccaPrivateCompanyAboutYou.path]: ccaPrivateCompanyAboutYou.config,
    [ccaPrivateCompanyOwnerDetails.path]: ccaPrivateCompanyOwnerDetails.config,
    [ccaPrivateCompanyUploadDocuments.path]:
      ccaPrivateCompanyUploadDocuments.config,
  },
};

const privateCompanyConfig: PagesConfig = {
  pages: {
    [businessDetails.path]: businessDetails.config,
    [ccaPrivateCompanyAboutYou.path]: ccaPrivateCompanyAboutYou.config,
    [ccaPrivateCompanyOwnerDetails.path]: ccaPrivateCompanyOwnerDetails.config,
    [ccaPrivateCompanyUploadDocuments.path]:
      ccaPrivateCompanyUploadDocuments.config,
  },
};

const configList = {
  pc: privateCompanyConfig,
  st: soleTraderConfig,
  np: nonProfitConfig,
  pa: partnershipConfig,
  pb: publicCompanyConfig,
  tr: trustConfig,
  privateCompanyWithCca,
  soleTraderWithCca,
  nonProfitWithCca,
  partnershipWithCca,
  publicCompanyWithCca,
  trustWithCca,
  withoutCca,
};

const digitalKycConfigList = {
  pc: privateCompanyConfigWithDigitalKyc,
  st: soleTraderConfigWithDigitalKyc,
  np: nonProfitConfigWithDigitalKyc,
  pa: partnershipConfigWithDigitalKyc,
  pb: publicCompanyConfigWithDigitalKyc,
  tr: trustConfigWithDigitalKyc,
  privateCompanyWithCcaWithDigitalKyc,
  soleTraderWithCcaWithDigitalKyc,
  nonProfitWithCcaWithDigitalKyc,
  partnershipWithCcaWithDigitalKyc,
  publicCompanyWithCcaWithDigitalKyc,
  trustWithCcaWithDigitalKyc,
  withoutCcaWithDigitalKyc,
};

const getPageStepConfig = (
  entityType: string,
  enableDigitalKyc: boolean
): PagesConfig => {
  return enableDigitalKyc
    ? digitalKycConfigList[getDigitalKycEntityType(entityType)]
    : configList[entityType];
};

function getDigitalKycEntityType(entityType) {
  return !Object.keys(entityTypeAbbreviations).includes(entityType)
    ? `${entityType}WithDigitalKyc`
    : entityType;
}

export default getPageStepConfig;

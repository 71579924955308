import { LegalEntities } from 'src/types';

// Region rules for primary user
export const evRegions: string[] = [
  LegalEntities.AU,
  LegalEntities.GB,
  LegalEntities.NZ,
  LegalEntities.HK,
  LegalEntities.SG,
];
export const onfidoRegions: string[] = [LegalEntities.US, LegalEntities.IE];
export const noDocumentRegions = [LegalEntities.CA];
export const beforeCorpOnboardingHideBusinessDocs: string[] = [
  LegalEntities.NZ,
  LegalEntities.HK,
  LegalEntities.SG,
];

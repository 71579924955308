import {
  BusinessTypesIds,
  BusinessTypesAbbreviations,
} from '../../../src/types';

export default [
  {
    id: BusinessTypesIds.PrivateCompany,
    name: 'Corporation',
    abbr: BusinessTypesAbbreviations.privateCompanyWithCca,
  },
  {
    id: BusinessTypesIds.SoleTrader,
    name: 'Sole Proprietorship',
    abbr: BusinessTypesAbbreviations.soleTraderWithCca,
  },
  {
    id: BusinessTypesIds.Partnership,
    name: 'Partnership / LP / LLP',
    abbr: BusinessTypesAbbreviations.withoutCca,
  },
  {
    id: BusinessTypesIds.Trust,
    name: 'Trust',
    abbr: BusinessTypesAbbreviations.withoutCca,
  },
  {
    id: BusinessTypesIds.NonProfit,
    name: 'Non-Profit / Charity',
    abbr: BusinessTypesAbbreviations.withoutCca,
  },
  {
    id: BusinessTypesIds.PublicCompany,
    name: 'Public Company',
    abbr: BusinessTypesAbbreviations.withoutCca,
  },
];

export const withAllCCA = [
  {
    id: BusinessTypesIds.PrivateCompany,
    name: 'Corporation',
    abbr: BusinessTypesAbbreviations.privateCompanyWithCca,
  },
  {
    id: BusinessTypesIds.SoleTrader,
    name: 'Sole Proprietorship',
    abbr: BusinessTypesAbbreviations.soleTraderWithCca,
  },
  {
    id: BusinessTypesIds.Partnership,
    name: 'Partnership / LP / LLP',
    abbr: BusinessTypesAbbreviations.partnershipWithCca,
  },
  {
    id: BusinessTypesIds.Trust,
    name: 'Trust',
    abbr: BusinessTypesAbbreviations.trustWithCca,
  },
  {
    id: BusinessTypesIds.NonProfit,
    name: 'Non-Profit / Charity',
    abbr: BusinessTypesAbbreviations.nonProfitWithCca,
  },
  {
    id: BusinessTypesIds.PublicCompany,
    name: 'Public Company',
    abbr: BusinessTypesAbbreviations.publicCompanyWithCca,
  },
];

export const businessRegistration = {
  landing: '/registration/business',
  products: '/registration/business/products',
  aboutGCA: '/registration/business/about-gca',
  businessDetails: '/registration/business/details',
  digitalKyc: '/registration/business/digital-kyc',
  aboutYou: '/registration/business/about-you',
  uploadDocuments: '/registration/upload',
  ownerDetails: '/registration/owner',
};

export const paytronRegistration = {
  businessDetails: '/registration/paytron',
  verification: '/registration/paytron/verification',
};

export const verification = {
  thankYou: '/thankyou',
  poa: '/verification/upload/poa',
};

const getCcaDefault = (entityTypeAbbr: string) => {
  return {
    aboutYou: `/cca/${entityTypeAbbr}/about`,
    digitalKyc: `/cca/${entityTypeAbbr}/digital-kyc`,
    thankyou: '/cca/thankyou',
    uploadDocuments: `/cca/${entityTypeAbbr}/upload`,
  };
};

export const cca = {
  privateCompany: {
    ...getCcaDefault('pc'),
    ownerDetails: '/cca/pc/shareholders',
  },
  soleTrader: getCcaDefault('st'),
  nonProfit: getCcaDefault('np'),
  partnership: getCcaDefault('pa'),
  publicCompany: getCcaDefault('pb'),
  trust: getCcaDefault('tr'),
};

import { BusinessTypesIds } from 'src/types';

export default {
  [BusinessTypesIds.PrivateCompany]: 'PrivateCompany',
  [BusinessTypesIds.SoleTrader]: 'SoleTrader',
  [BusinessTypesIds.Partnership]: 'Partnership',
  [BusinessTypesIds.Trust]: 'Trust',
  [BusinessTypesIds.NonProfit]: 'NonProfit',
  [BusinessTypesIds.PublicCompany]: 'PublicCompany',
  [BusinessTypesIds.Individual]: 'Individual',
};

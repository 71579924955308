// References: https://github.com/DataDog/rum-react-integration-examples
import React, { useContext, useMemo } from 'react';

import type { ComponentContext } from './componentContext';
import { RumComponentContext } from './componentContext';

/**
 * Context Provider to add a new component to the action breadcrumbs. Useful for class Components.
 */
interface Props {
  componentName: string;
  customAttributes?: object;
  children?: React.ReactNode;
}

export const RumComponentContextProvider: React.FunctionComponent<{
  componentName: string;
  customAttributes?: object;
}> = ({ componentName, customAttributes, children }: Props) => {
  const parentContext = useContext(RumComponentContext);
  const newContext = useMemo<ComponentContext>(
    () => ({
      component: componentName,
      customAttributes: {
        ...parentContext.customAttributes,
        ...customAttributes,
      },
      componentBreadCrumbs: `${parentContext.componentBreadCrumbs}.${componentName}`,
    }),
    [
      componentName,
      parentContext.componentBreadCrumbs,
      parentContext.customAttributes,
      customAttributes,
    ]
  );
  return (
    <RumComponentContext.Provider value={newContext}>
      {children}
    </RumComponentContext.Provider>
  );
};
/**
 * Decorator to add a new component to the breadcrumbs when using useRumAction or useRumTracking action hooks
 * the decorator is better than a just component because it will add the context to everything in your component
 */
export function withRumComponentContext<PropsType>(
  componentName: string,
  options:
    | {
        customAttributes?: object;
      }
    | undefined,
  Component: React.FunctionComponent<PropsType>
): React.FunctionComponent<PropsType>;

export function withRumComponentContext<PropsType>(
  componentName: string,
  Component: React.FunctionComponent<PropsType>
): React.FunctionComponent<PropsType>;

export function withRumComponentContext<PropsType>(
  componentName: string,
  options: any,
  Component?: React.FunctionComponent<PropsType>
): React.FunctionComponent<PropsType> {
  if (typeof Component === 'undefined') {
    return withRumComponentContext(componentName, {}, options);
  }

  // eslint-disable-next-line react/display-name
  return (props: PropsType) => {
    return (
      <RumComponentContextProvider
        componentName={componentName}
        customAttributes={options.customAttributes}
      >
        <Component {...props} />
      </RumComponentContextProvider>
    );
  };
}

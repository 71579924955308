import getPageStepsConfig from 'src/config/pageSteps';
import entityTypes from 'src/constants/entityTypes';
import getBusinessTypesByLegalEntity from 'src/util/businessTypes';
import { User, BusinessDetails } from 'src/types';
import { getBusinessType } from 'src/util/getBusinessType';
import {
  businessRegistration as businessRegoRoutes,
  paytronRegistration as paytronRegoRoutes,
  verification as verificationRoutes,
} from 'src/config/routes';
import { useFlags } from 'launchdarkly-react-client-sdk';

const hiddenStepperPages = [
  '/',
  verificationRoutes.thankYou,
  businessRegoRoutes.landing,
  businessRegoRoutes.products,
  businessRegoRoutes.aboutGCA,
  paytronRegoRoutes.businessDetails,
  paytronRegoRoutes.verification,
];

const noDataBasedSteps = {
  [verificationRoutes.poa]: ['Your location', 'Your details', 'Verification'],
};

export const getStepperConfig = (
  data: User | BusinessDetails,
  path: string
): { steps: string[]; nextStep: number } => {
  if (noDataBasedSteps[path]) {
    return { steps: noDataBasedSteps[path], nextStep: 2 };
  }

  if (!data || hiddenStepperPages.includes(path))
    return { steps: [], nextStep: 0 };

  const isRegistration = path.includes('/registration');
  let businessType;

  const { enableDigitalKyc } = useFlags();

  if (isRegistration) {
    const { enableCorporateOnboardingFrontEnd } = useFlags();
    const _data = data as BusinessDetails;
    businessType = getBusinessType(
      getBusinessTypesByLegalEntity(
        _data.locale,
        enableCorporateOnboardingFrontEnd
      ),
      _data,
      enableCorporateOnboardingFrontEnd
    );
  } else {
    const _data = data as User;
    businessType = entityTypes[_data.entityTypeId || _data['businessType']]; // Using square bracket notation to bypass TS
  }

  const pageStepsConfig =
    getPageStepsConfig(businessType?.abbr, enableDigitalKyc)?.pages || {};
  const steps: string[] = Object.keys(pageStepsConfig)
    .reduce((r, path) => [...r, (pageStepsConfig[path] || {}).stepperLabel], [])
    .filter((el) => el !== null);

  const nextStep = pageStepsConfig[path]
    ? steps.indexOf(pageStepsConfig[path].stepperLabel)
    : 0;

  return {
    steps,
    nextStep,
  };
};

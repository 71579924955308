import React, { FunctionComponent } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Header from '../Header';
import theme from '../../fox/Theme/MuiTheme';
import { useRouter } from 'next/router';
import { scrollToTop } from '../../util/scrollUtil';

const useStyles = makeStyles((theme: Theme) => {
  const hiResUp = `@media only screen and (-webkit-min-device-pixel-ratio: 2),
    @media only screen and (min-resolution: 2dppx)
  `;

  return {
    pageWrapper: {
      background: `linear-gradient(
        rgba(${theme.fox.colors.neutral.s100.rgb},1),
        rgba(${theme.fox.colors.neutral.s0.rgb},0) 165px
      )`,
      [theme.breakpoints.up('md')]: {
        backgroundRepeat: 'no-repeat',
        backgroundPositionY: 'bottom',
        backgroundSize: 'auto, contain',
        width: '100%;',
        height: 'auto',
        minHeight: '100%',
        backgroundImage: `linear-gradient(
          rgba(${theme.fox.colors.neutral.s100.rgb},1),
          rgba(${theme.fox.colors.neutral.s0.rgb},0) 165px
        ),
        url("/static/medium-bg.png")`,
        [hiResUp]: {
          backgroundImage: `linear-gradient(
              rgba(${theme.fox.colors.neutral.s100.rgb},1),
              rgba(${theme.fox.colors.neutral.s0.rgb},0) 165px
            ),
            url("/static/medium-bg@2x.png")`,
        },
      },
    },
  };
});

interface Props {
  children: React.ReactNode;
  jsEnabled: boolean;
}

const Layout: FunctionComponent<Props> = ({
  children = <></>,
  jsEnabled = true,
}: Props): React.ReactElement => {
  const classes = useStyles({});
  const router = useRouter();

  React.useEffect(() => {
    scrollToTop('#header');
  }, [router.pathname]);

  return (
    <>
      {jsEnabled && <Header />}
      <div className={classes.pageWrapper}>
        <Box
          maxWidth="696px"
          margin="0 auto"
          paddingX={`${theme.spacing(2)}px`}
        >
          {children}
        </Box>
      </div>
    </>
  );
};

export default Layout;

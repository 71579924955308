import {
  IUserCreated,
  IComplianceCheckResultChanged,
  ComplianceEventTypes,
  IPassfortWebhookResult,
  AllComplianceEvents,
} from '../WebsocketEvents/ComplianceEvents';
import { IWebSocketEvent } from '../WebsocketEvents/WebsocketEvents';
import { AllowedPassfortWebHooks } from '../constants';

/* tslint:disable:no-any */
const createUserCreatedEvent = (
  messageData: any,
  eventType: ComplianceEventTypes
): IUserCreated => {
  try {
    const userCreated: IUserCreated = {
      complianceEventType: eventType,
      userId: messageData.UserId,
      isActive: messageData.IsActive,
      createdDateTime: new Date(messageData.CreatedDateTime),
      passfortMigrationStatus: messageData.PassfortMigrationStatus,
    };
    return userCreated;
  } catch {
    throw new Error('CreateUserCreatedEvent or CreateUserUpdateEvent failed');
  }
};

const createComplianceCheckResultChangedEvent = (
  messageData: any
): IComplianceCheckResultChanged => {
  try {
    const ccResultEvent: IComplianceCheckResultChanged = {
      complianceEventType: ComplianceEventTypes.COMPLIANCE_CHECKRESULT_CHANGED,
      userId: messageData.UserId,
      type: messageData.Type,
      isPassed: messageData.IsPassed,
      lastUpdated: new Date(messageData.LastUpdated),
    };
    return ccResultEvent;
  } catch {
    throw new Error('CreateccResultEvent failed');
  }
};

const createPassfortWebhookResult = (
  messageData: any
): IPassfortWebhookResult => {
  try {
    if (!AllowedPassfortWebHooks.includes(messageData.EventType)) {
      throw new Error(
        `Unknown PassfortWebHook Message Type': ${messageData.EventType}`
      );
    }
    const passfortWebResultEvent: IPassfortWebhookResult = {
      complianceEventType: ComplianceEventTypes.PASSFORT_WEB_HOOK_RESULT,
      userId: messageData.UserId,
      eventType: messageData.EventType,
      status: messageData.Status,
      lastUpdated: new Date(messageData.LastUpdated),
      createdDateTime: new Date(messageData.CreatedDateTime),
      IsRetryAllowed: messageData.IsRetryAllowed,
      profileId: messageData.ProfileId,
    };
    return passfortWebResultEvent;
  } catch (error) {
    throw new Error(`CreateWebHookResultEvent failed: ${error.message}`);
  }
};

const parseMessageInEvent = (webSocketdata: IWebSocketEvent) => {
  try {
    const { data } = webSocketdata;
    return JSON.parse(data);
  } catch {
    throw Error('Invalid message data from event');
  }
};

export const createComplianceObject = (
  webSocketdata: IWebSocketEvent
): AllComplianceEvents => {
  const messageData = parseMessageInEvent(webSocketdata);
  switch (webSocketdata.type) {
    case ComplianceEventTypes.USER_CREATED:
    case ComplianceEventTypes.USER_UPDATED:
      return createUserCreatedEvent(messageData, webSocketdata.type);
    case ComplianceEventTypes.COMPLIANCE_CHECKRESULT_CHANGED:
      return createComplianceCheckResultChangedEvent(messageData);
    case ComplianceEventTypes.PASSFORT_WEB_HOOK_RESULT:
      return createPassfortWebhookResult(messageData);
    // Handle other types
    default:
      throw new Error(`Unsupported object type: ${webSocketdata.type}`);
  }
};

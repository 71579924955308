import React from 'react';
import MuiButton from '@material-ui/core/Button';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { withTheme } from '@material-ui/core/styles';
import { TrackingProps } from '../../Types';

// TODO: Add other button types from https://ofx.invisionapp.com/share/QXT4GH7UJDR#/screens/376389449_Button_States
export type ButtonVariants =
  | 'primary'
  | 'secondary'
  | 'link'
  | 'ghost'
  | 'filled';
export type ButtonType = 'button' | 'submit';

// Make sure we have all button types as properties here
const useStyles = makeStyles((theme: Theme) => {
  const { colors, typeStyles } = theme.fox;

  return {
    primary: {
      '& > *': {
        pointerEvents: 'none',
      },
      width: '100%',
      height: '48px',
      fontFamily: typeStyles.body1.fontFamily,
      fontSize: typeStyles.body1.fontSize,
      fontWeight: 600,
      color: 'white',
      backgroundColor: colors.orange.s600.hex,
      borderRadius: '24px',
      padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
      '&:hover': {
        backgroundColor: colors.orange.s500.hex,
      },
      '&:active': {
        backgroundColor: colors.orange.s600.hex,
      },
      '&:disabled': {
        color: colors.neutral.s0.hex,
        backgroundColor: colors.neutral.s300.hex,
      },
    },
    secondary: {
      '& > *': {
        pointerEvents: 'none',
      },
      width: '100%',
      height: '48px',
      fontFamily: typeStyles.body1.fontFamily,
      fontSize: typeStyles.body1.fontSize,
      fontWeight: 600,
      color: colors.neutral.s700.hex,
      backgroundColor: theme.fox.colors.neutral.s200.hex,
      borderRadius: '24px',
      padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
      '&:hover': {
        backgroundColor: colors.neutral.s100.hex,
      },
      '&:active': {
        backgroundColor: colors.neutral.s200.hex,
      },
    },
    link: {
      '& > *': {
        pointerEvents: 'none',
      },
      color: colors.neutral.s800.hex,
      textTransform: 'none',
      fontFamily: typeStyles.caption.fontFamily,
      fontWeight: 800,
      textDecoration: 'underline',
      '&:hover': {
        backgroundColor: 'transparent',
      },
      '& .MuiButton-label': {
        fontSize: typeStyles.caption.fontSize,
      },
    },
    ghost: {
      '& > *': {
        pointerEvents: 'none',
      },
      border: `2px solid ${colors.neutral.s700.hex}`,
      fontFamily: typeStyles.button2.fontFamily,
      fontSize: typeStyles.button2.fontSize,
      fontWeight: 600,
      color: colors.neutral.s700.hex,
      borderRadius: '18px',
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      '&:hover': {
        backgroundColor: colors.neutral.s100.hex,
      },
      '&:active': {
        backgroundColor: colors.neutral.s200.hex,
      },
    },
    filled: {
      '& > *': {
        pointerEvents: 'none',
      },
      backgroundColor: theme.fox.colors.neutral.s800.hex,
      borderRadius: '24px',
      padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
      '& .MuiButton-label': {
        color: 'white',
      },
      '&:hover': {
        backgroundColor: theme.fox.colors.neutral.s700.hex,
      },
    },
  };
});

const getVariantName = (
  type: ButtonVariants
): undefined | 'text' | 'outlined' => {
  switch (type) {
    case 'link':
      return 'text';
    case 'ghost':
      return 'outlined';
    default:
      return undefined;
  }
};

const getVariantSize = (type: ButtonVariants): 'small' | 'medium' | 'large' => {
  switch (type) {
    case 'link':
      return 'large';
    case 'ghost':
      return 'small';
    default:
      return 'large';
  }
};

interface ButtonProps extends TrackingProps {
  children?: React.ReactNode;
  variant: ButtonVariants;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  className?: string;
  type?: ButtonType;
  disabled?: boolean;
  fullWidth?: boolean;
}

const Button = ({
  children,
  variant,
  onClick,
  className,
  type = 'button',
  disabled = false,
  fullWidth = false,
  trackingId,
}: ButtonProps): React.ReactElement<ButtonProps> => {
  const classes = useStyles({});

  const classesForVariant = [classes[variant]];

  if (className) {
    classesForVariant.push(className);
  }

  return (
    <MuiButton
      type={type}
      variant={getVariantName(variant)}
      size={getVariantSize(variant)}
      className={classesForVariant.join(' ')}
      disableRipple={variant === 'link'}
      disabled={disabled}
      onClick={onClick}
      fullWidth={fullWidth}
      data-tracking-id={trackingId}
    >
      {children}
    </MuiButton>
  );
};

export default withTheme(Button);

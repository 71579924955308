/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  FoxTheme,
  FoxColorTypes,
  FoxColorSet,
  FoxTypeStyleNames,
  FoxTypeStyle,
} from '../Types';
import styleData from './style-data.json';

// TODO: Do this on compile time or create from a generator
const convertHexToRgb = (hex: string): string => {
  hex = hex.replace('#', '');
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);
  return `${r},${g},${b}`;
};

const convertFirstCharToLowerCase = (input: string): string =>
  input.replace(/^\w/, (co: string): string => co.toLowerCase());

const colors: {
  [colorTypes in FoxColorTypes]: FoxColorSet;
} = styleData.list.colors.reduce((colorObj: any, c: any): any => {
  // Grab all colors under the main color array and create an object with the FoxColorTypes as properties
  colorObj[convertFirstCharToLowerCase(c.name)] = c.colors.reduce(
    (obj: any, item: any): any => {
      // Under the created FoxColorTypes, create the FoxColorShades
      obj[item.name.replace(`${c.name}-`, 's')] = {
        hex: item.value,
        rgb: convertHexToRgb(item.value),
      };
      return obj;
    },
    {}
  );
  return colorObj;
}, {});

const typeStyles: {
  [styleNames in FoxTypeStyleNames]: FoxTypeStyle;
} = styleData.list.typeStyles.reduce((typeObj: any, c: any): any => {
  typeObj[convertFirstCharToLowerCase(c.name.replace(' ', ''))] = c;
  return typeObj;
}, {});

const theme: FoxTheme = {
  colors,
  typeStyles,
};

export default theme;

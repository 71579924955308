import au, { withAllCCA as auWithAllCCA } from '../constants/businessTypes/au';
import ca, { withAllCCA as caWithAllCCA } from '../constants/businessTypes/ca';
import gb, { withAllCCA as gbWithAllCCA } from '../constants/businessTypes/gb';
import hk, { withAllCCA as hkWithAllCCA } from '../constants/businessTypes/hk';
import nz, { withAllCCA as nzWithAllCCA } from '../constants/businessTypes/nz';
import sg, { withAllCCA as sgWithAllCCA } from '../constants/businessTypes/sg';
import us, { withAllCCA as usWithAllCCA } from '../constants/businessTypes/us';
import ie, { withAllCCA as ieWithAllCCA } from '../constants/businessTypes/ie';

import { LegalEntities } from '../types';

interface BusinessType {
  id: string;
  name: string;
  abbr: string;
}

export default function getBusinessTypeByLegalEntity(
  legalEntity,
  isNewCorporateOnboardingFlow = false
): BusinessType[] {
  switch (legalEntity) {
    case LegalEntities.AU:
      if (isNewCorporateOnboardingFlow) return auWithAllCCA;
      return au;

    case LegalEntities.CA:
      if (isNewCorporateOnboardingFlow) return caWithAllCCA;
      return ca;

    case LegalEntities.GB:
      if (isNewCorporateOnboardingFlow) return gbWithAllCCA;
      return gb;

    case LegalEntities.HK:
      if (isNewCorporateOnboardingFlow) return hkWithAllCCA;
      return hk;

    case LegalEntities.NZ:
      if (isNewCorporateOnboardingFlow) return nzWithAllCCA;
      return nz;

    case LegalEntities.SG:
      if (isNewCorporateOnboardingFlow) return sgWithAllCCA;
      return sg;

    case LegalEntities.US:
      if (isNewCorporateOnboardingFlow) return usWithAllCCA;
      return us;

    case LegalEntities.IE:
      if (isNewCorporateOnboardingFlow) return ieWithAllCCA;
      return ie;

    default:
      return au;
  }
}

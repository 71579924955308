import cookies from 'js-cookie';
import authConstants from '../../constants/auth';

export function getAccessToken(): string {
  const ofxCookie = cookies.get(authConstants.cookieName);
  let accessToken = '';
  if (ofxCookie) {
    accessToken = JSON.parse(ofxCookie).access_token || {};
  }
  return accessToken;
}

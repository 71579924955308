import React, { useContext } from 'react';
import { useRouter } from 'next/router';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Stepper from '../../fox/components/Stepper';
import withStore from '../Store/withStore/withStore';
import Store from '../Store/Store';
import { Store as StoreInterface } from '../../types';
import { getStepperConfig } from './getPageStepperConfig';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: 1024,
      margin: '0 auto',
    },
    button: {
      marginRight: theme.spacing(1),
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  })
);

const PageStepper: React.FunctionComponent = (): React.ReactElement => {
  const router = useRouter();
  const classes = useStyles({});

  const {
    user: { data: userData },
    businessDetails: { data: businessDetails },
  } = useContext(Store) as StoreInterface;

  const { nextStep, steps } = getStepperConfig(
    userData || businessDetails,
    router.asPath
  );

  return steps.length > 0 ? (
    <div className={classes.root}>
      <Stepper steps={steps} activeStep={nextStep} />
    </div>
  ) : null;
};

export default withStore(PageStepper);

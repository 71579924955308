// Passfort migration status, CTD , Company data check
export type AllComplianceEvents =
  | IUserCreated
  | IUserUpdated
  | IComplianceCheckResultChanged
  | IPassfortWebhookResult;

export enum PassfortWebhookEventTypes {
  COMPANY_DATA_CHECK = 'COMPANY_DATA_CHECK',
  UBO_IDENTITY_CHECK = 'UBO_IDENTITY_CHECK',
  PRIMARY_USER_IDENTITY_CHECK = 'CORP_EV_CHECK',
  CORP_COMPANY_DETAIL_VERIFICATION = 'CORP_COMPANY_DETAIL_VERIFICATION',
  IDENTITY_CHECK_CORPORATE = 'IDENTITY_CHECK_CORPORATE',
}

export enum CommonStatus {
  PASS = 'PASS',
  FAIL = 'FAIL',
  ERROR = 'ERROR',
  IN_PROGRESS = 'IN_PROGRESS',
  EMPTY = '',
}

// According to monir EV does not have in_progress. Rely on front end list to know ev should run.
export enum UniqueEVStatuses {
  PARTIAL = 'PARTIAL',
}

export enum PassfortMigrationStatus {
  IN_PROGRESS = 'IN_PROGRESS',
  FAILED = 'FAILED',
  MIGRATED = 'MIGRATED',
}

export enum UniqueCompanyDataCheckStatuses {
  PARTIAL = 'PARTIAL',
}

export enum UniqueCorpCompanyDetailVerificationStatuses {
  COMPLETED_PASS = 'COMPLETED_PASS',
  COMPLETED_FAIL = 'COMPLETED_FAIL',
  INCOMPLETE = 'INCOMPLETE',
}

export enum IdentityCheck {
  COMPLETED_PASS = 'COMPLETED_PASS',
  INCOMPLETE = 'INCOMPLETE',
}

type CompanyDataCheckStatuses = UniqueCompanyDataCheckStatuses | CommonStatus;

export type AllEVStatuses = UniqueEVStatuses | CommonStatus;

type CorpCompanyDetailVerificationStatuses =
  UniqueCorpCompanyDetailVerificationStatuses;

type AllPassfortWebhookEventStatuses =
  | CommonStatus
  | PassfortMigrationStatus
  | CompanyDataCheckStatuses
  | CorpCompanyDetailVerificationStatuses
  | AllEVStatuses;

interface ICommonEventFields {
  userId: string;
  complianceEventType: ComplianceEventTypes;
}

export interface IUserCreated extends ICommonEventFields {
  isActive: boolean;
  createdDateTime: Date;
  passfortMigrationStatus: PassfortMigrationStatus;
}

export interface IUserUpdated extends ICommonEventFields {
  isActive: boolean;
  createdDateTime: Date;
  passfortMigrationStatus: PassfortMigrationStatus;
}

export interface IComplianceCheckResultChanged extends ICommonEventFields {
  type: IComplianceCheckResultChangedTypes;
  isPassed: boolean;
  lastUpdated: Date;
}

export interface IPassfortWebhookResult extends ICommonEventFields {
  eventType: PassfortWebhookEventTypes;
  status: AllPassfortWebhookEventStatuses;
  lastUpdated: Date;
  createdDateTime: Date;
  IsRetryAllowed: boolean | null;
  profileId: string;
}

export enum IComplianceCheckResultChangedTypes {
  ClearToPayAsRecipient = 'ClearToPayAsRecipient',
  ClearToPayAsFullUser = 'ClearToPayAsFullUser',
  ClearToDeal = 'ClearToDeal',
  ClearToPayAsFullPartner = 'ClearToPayAsFullPartner',
}

export enum ComplianceEventTypes {
  USER_CREATED = 'UserCreated',
  USER_UPDATED = 'UserUpdated',
  COMPLIANCE_CHECKRESULT_CHANGED = 'ComplianceCheckResultChanged',
  PASSFORT_WEB_HOOK_RESULT = 'PassfortWebhookResult',
}

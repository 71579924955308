import { createTheme } from '@material-ui/core/styles';
import { red } from '@material-ui/core/colors';
import { FoxTheme } from '../Types';
import theme from './Theme';

declare module '@material-ui/core/styles/createTheme' {
  interface Theme {
    fox: FoxTheme;
  }

  interface ThemeOptions {
    fox: FoxTheme;
  }
}

const muiTheme = createTheme({
  palette: {
    primary: {
      main: theme.colors.neutral.s800.hex,
    },
    secondary: {
      main: theme.colors.neutral.s800.hex,
    },
    error: {
      main: red.A400,
    },
    background: {
      default: '#fff',
    },
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        a: {
          color: theme.colors.neutral.s900.hex,
        },
      },
    },
  },
  fox: theme,
});

export default muiTheme;

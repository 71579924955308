import { useMemo } from 'react';
import { ApolloClient, from, HttpLink, InMemoryCache } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import getConfig from 'next/config';
import cookies from 'next-cookies';
import { v4 as uuidv4 } from 'uuid';
import { getAccessToken } from 'src/http/auth/auth';

const { publicRuntimeConfig } = getConfig();
const isSSR = typeof window === 'undefined';

function createApolloClient(ctx): ApolloClient<{}> {
  let ofxCookie;
  let cookieObj;

  // [TODO]: create a util function for this cookie retrieval as it's used elsewhere also
  if (ctx) {
    ofxCookie = cookies(ctx).ofx || '';
    if (ofxCookie) {
      cookieObj = JSON.parse(ofxCookie);
    }
  }

  const token = isSSR ? cookieObj?.access_token : getAccessToken();

  const getHeaders = (): {} => {
    if (token)
      return {
        authorization: `Bearer ${token}`,
        'x-ofx-correlationid': uuidv4(),
      };
    return {};
  };
  return new ApolloClient({
    ssrMode: isSSR,
    name: 'helios',
    version: publicRuntimeConfig.appVersion,
    link: from([
      onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors)
          graphQLErrors.map(({ message, locations, path }) =>
            console.log(
              `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
            )
          );

        if (networkError) console.log(`[Network error]: ${networkError}`);
      }),
      new HttpLink({
        uri: publicRuntimeConfig.graphqlEndpoint,
        headers: getHeaders(),
      }),
    ]),
    cache: new InMemoryCache(),
  });
}

export function initializeApollo(initialState = null, ctx?): ApolloClient<{}> {
  let apolloClient;
  const _apolloClient = apolloClient ?? createApolloClient(ctx);

  // Hydrate initial state for SSG/SSR here
  if (initialState) {
    // Merge existing cache with newly fetched data
    const existingCache = _apolloClient.extract();
    _apolloClient.cache.restore({ ...existingCache, ...initialState });
  }
  // For SSG and SSR always create a new Apollo Client
  if (isSSR) return _apolloClient;
  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient;

  return _apolloClient;
}

export function useApollo(initialState, ctx?): ApolloClient<{}> {
  return useMemo(
    () => initializeApollo(initialState, ctx),
    [initialState, ctx]
  );
}

import { Amplify, Hub } from 'aws-amplify';
import { API, graphqlOperation, GraphQLSubscription } from '@aws-amplify/api';
import withStore from '@components/Store/withStore/withStore';
import {
  LegalEntities,
  Shareholder,
  Store,
  WebsocketOperationalStatus,
} from 'src/types';
import { useEffect, useRef, useState } from 'react';
import { getAccessToken } from 'src/http/auth/auth';
import { SubscribeToChannel } from './graphql/subscriptions';
import { authenticationType, awsAppSyncRegion, Channel } from './constants';
import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk';
import getConfig from 'next/config';
import { ldIdentify } from 'src/util/launchDarklyIdentify';
import { createComplianceObject } from './WebsocketHelpers/ComplianceEventHelper';
import { IWebSocketEvent } from './WebsocketEvents/WebsocketEvents';
import businessTypesDictionary from 'src/constants/businessTypesDictionary';
import {
  AllComplianceEvents,
  CommonStatus,
  ComplianceEventTypes,
  IPassfortWebhookResult,
  PassfortWebhookEventTypes,
  UniqueEVStatuses,
} from './WebsocketEvents/ComplianceEvents';
import isEqual from 'lodash/isEqual';
import { evRegions, noDocumentRegions } from 'src/constants/upload';
import { CONNECTION_STATE_CHANGE, ConnectionState } from '@aws-amplify/pubsub';

export const CompletedStatuses = [
  UniqueEVStatuses.PARTIAL,
  CommonStatus.PASS,
  CommonStatus.ERROR,
  CommonStatus.FAIL,
];

const WebsocketHandler: React.FC<Store> = (props: Store) => {
  const { enableCorporateOnboardingFrontEnd } = useFlags();
  const [webSocketStarted, setWebSocketStarted] = useState(false);
  const ldClient = useLDClient();
  let complianceAPISubscription;

  useEffect(() => {
    const identifyUser = async () => {
      if (
        props.user &&
        props.user.data &&
        props.user.data.entityTypeId &&
        props.user.data.locale &&
        props.user.data.country &&
        ldClient
      ) {
        await ldIdentify(ldClient, {
          custom: {
            entityType: businessTypesDictionary[props.user.data.entityTypeId],
            isoLegalEntity: props.user.data.locale,
            country: props.user.data.country,
          },
        });
      }
    };
    identifyUser();
  }, [props.user, ldClient]);

  const storeEvent = (value) => {
    const processedComplianceEvent: AllComplianceEvents =
      createComplianceObject(value.data.subscribe);
    props.setWebsocketEvents({
      ...props.websocketEvents,
      complianceEvent: processedComplianceEvent,
    });
  };

  const establishSubscriptions = () => {
    const accessToken = getAccessToken();
    const variables = {
      userId: props.user.data.id ? props.user.data.id.toUpperCase() : '',
      name: Channel.COMPLIANCE,
    };
    complianceAPISubscription = API.graphql<
      GraphQLSubscription<IWebSocketEvent>
    >(graphqlOperation(SubscribeToChannel, variables, accessToken)).subscribe({
      next: ({ value }) => storeEvent(value),
      error: (error) => console.warn(error),
    });
    setWebSocketStarted(true);
  };

  Hub.listen('api', (data: any) => {
    const { payload } = data;
    if (payload.event === CONNECTION_STATE_CHANGE) {
      const connectionState = payload.data.connectionState as ConnectionState;

      if (connectionState === ConnectionState.Disconnected) {
        props.setWebsocketEvents({
          ...props.websocketEvents,
          operationalStatus: WebsocketOperationalStatus.Disconnected,
        });
      }

      if (connectionState === ConnectionState.Connected) {
        props.setWebsocketEvents({
          ...props.websocketEvents,
          operationalStatus: WebsocketOperationalStatus.Connected,
        });
      }
    }
  });

  useEffect(() => {
    if (
      props.user.data &&
      enableCorporateOnboardingFrontEnd &&
      !webSocketStarted
    ) {
      const { publicRuntimeConfig } = getConfig();
      Amplify.configure({
        aws_appsync_graphqlEndpoint:
          publicRuntimeConfig.webSocketGraphqlEndpoint,
        aws_appsync_region: awsAppSyncRegion,
        aws_appsync_authenticationType: authenticationType,
      });
      establishSubscriptions();
    }
    return () => {
      if (webSocketStarted && complianceAPISubscription) {
        complianceAPISubscription.unsubscribe();
        setWebSocketStarted(false);
      }
    };
  }, [enableCorporateOnboardingFrontEnd, props.user]);

  return null;
};

interface EventMapperHook {
  allEVEventsReceived: boolean;
  shareholdersNeedToUploadDocs: string[];
}

type ShareholderDict = {
  [key: string]: string;
};

export interface PrimaryUserStatus {
  profileID: string;
  evStatus: string;
  locale: LegalEntities;
}

export interface EVMapperData {
  primaryUserData: PrimaryUserStatus;
  submittedUBOs: Array<Shareholder>;
}

function usePrevious(value): EVMapperData {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export const useEventMapper = (
  primaryUser: PrimaryUserStatus,
  submittedUBOs: Array<Shareholder>,
  complianceEvent: AllComplianceEvents
): EventMapperHook => {
  const [allEVEventsReceived, setAllEventsReceived] = useState<boolean>(false);
  const [shareholderEVMapper, setShareholderEVMapper] = useState({});
  // Initially undefined until processing once
  const [shareholdersNeedToUploadDocs, setShareholdersNeedToUploadDocs] =
    useState<string[]>(undefined);
  const prevPrimaryAndUBOs = usePrevious({ submittedUBOs, primaryUser });
  useEffect(() => {
    // Primary User should exist, UBOs must exist
    if (
      primaryUser &&
      primaryUser.profileID &&
      submittedUBOs.length > 0 &&
      !isEqual(prevPrimaryAndUBOs, { submittedUBOs, primaryUser })
    ) {
      const shareholderDict: ShareholderDict = submittedUBOs.reduce(
        (acc, ubo) => {
          const shareholderId = ubo.id;
          acc[shareholderId] = ubo.evStatus;
          return acc;
        },
        {}
      );
      if (evRegions.includes(primaryUser.locale)) {
        shareholderDict[primaryUser.profileID] = primaryUser.evStatus;
      }
      setShareholderEVMapper(shareholderDict);
    }
  }, [submittedUBOs, primaryUser]);

  useEffect(() => {
    // Events can't have come before submitted UBOs
    if (
      complianceEvent &&
      complianceEvent.complianceEventType &&
      complianceEvent.complianceEventType ===
        ComplianceEventTypes.PASSFORT_WEB_HOOK_RESULT
    ) {
      const passfortWebHook = complianceEvent as IPassfortWebhookResult;
      if (
        passfortWebHook.eventType ===
        PassfortWebhookEventTypes.UBO_IDENTITY_CHECK
      ) {
        // This users status was completed
        setShareholderEVMapper({
          ...shareholderEVMapper,
          ...{ [passfortWebHook.profileId]: passfortWebHook.status },
        });
      }
    }
  }, [complianceEvent]);

  useEffect(() => {
    // CA Does not upload documents, no need to wait
    if (
      primaryUser &&
      primaryUser.locale &&
      noDocumentRegions.includes(primaryUser.locale)
    ) {
      setAllEventsReceived(true);
      return;
    }
    // First rendered before any submitted ubos or primary user
    if (submittedUBOs.length === 0 || !primaryUser || !primaryUser.profileID) {
      return;
    }
    // Can process because info is all there
    const newShareholderNeedToUploadDoc = [];
    let userEventNotReceived = false;
    for (const key in shareholderEVMapper) {
      if (shareholderEVMapper[key] !== CommonStatus.PASS) {
        newShareholderNeedToUploadDoc.push(key);
      }
      // This could return blank
      if (!CompletedStatuses.includes(shareholderEVMapper[key])) {
        userEventNotReceived = true;
      }
    }
    setShareholdersNeedToUploadDocs(newShareholderNeedToUploadDoc);
    if (!userEventNotReceived) {
      setAllEventsReceived(true);
    }
  }, [shareholderEVMapper]);

  return { allEVEventsReceived, shareholdersNeedToUploadDocs };
};

const WebsocketHandlerWithStore = withStore(WebsocketHandler);

WebsocketHandlerWithStore.displayName = 'WebSocketHandler';

export { WebsocketHandlerWithStore, WebsocketHandler };

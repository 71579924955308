import React, { Component, PropsWithChildren, ReactElement } from 'react';
import Store from '../Store';
import {
  ApiState,
  BusinessDetails,
  I18n,
  IWebsocketEvents,
  Store as IStore,
  User,
  UserDetail,
  WebsocketOperationalStatus,
  PaytronUserDetails,
} from '../../../types';

interface State {
  userDetail: ApiState<UserDetail>;
  i18n?: I18n;
  user: ApiState<User>;
  businessDetails: ApiState<BusinessDetails>;
  sessionId: ApiState<string>;
  showSystemErrorMessage: ApiState<boolean>;
  isPassfortMigrated: boolean;
  websocketEvents: IWebsocketEvents;
  paytronUserDetails: PaytronUserDetails;
}

const initialState: State = {
  user: {
    data: null,
    isError: false,
  },
  userDetail: {
    data: null,
    isError: false,
  },
  businessDetails: {
    data: null,
    isError: false,
  },
  sessionId: {
    data: '',
    isError: false,
  },
  showSystemErrorMessage: {
    data: false,
    isError: false,
  },
  isPassfortMigrated: false,
  websocketEvents: {
    operationalStatus: WebsocketOperationalStatus.Initializing,
    complianceEvent: undefined,
  },
  paytronUserDetails: {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    phoneNumber: '',
    userAgreement: false,
  },
};

export default class StoreProvider extends Component<
  PropsWithChildren<{}>,
  State
> {
  public static displayName = 'StoreProvider';

  public constructor(props: {}) {
    super(props);
    this.state = initialState;
  }

  public setSessionId = (data: string): void => {
    this.setState({
      sessionId: {
        data,
        isError: false,
      },
    });
  };

  public setShowSystemErrorMessage = (data: boolean): void => {
    this.setState({
      showSystemErrorMessage: {
        data,
        isError: false,
      },
    });
  };

  public setBusinessDetails = (data: BusinessDetails): void => {
    this.setState({
      businessDetails: {
        data,
        isError: false,
      },
    });
  };

  public setUserData = (userData: User): void => {
    this.setState({
      user: {
        data: userData,
        isError: false,
      },
    });
  };

  public setUserError = (): void => {
    this.setState({
      user: {
        ...this.state.user,
        isError: true,
      },
    });
  };

  public setUserDetailData = (userDetail: UserDetail): void => {
    this.setState({
      userDetail: {
        data: userDetail,
        isError: false,
      },
    });
  };

  public setUserDetailError = (): void => {
    this.setState({
      userDetail: {
        ...this.state.userDetail,
        isError: true,
      },
    });
  };

  public setI18n = (i18nData: I18n): void => {
    this.setState({
      i18n: i18nData,
    });
  };

  public setPassfortMigrationStatus = (isMigratedUser: boolean) => {
    this.setState({
      isPassfortMigrated: isMigratedUser,
    });
  };

  public setWebsocketEvents = (newEvents: IWebsocketEvents) => {
    this.setState({
      websocketEvents: newEvents,
    });
  };

  public setPaytronUserDetails = (
    newPaytronUserDetails: PaytronUserDetails
  ) => {
    this.setState({
      paytronUserDetails: {
        ...this.state.paytronUserDetails,
        ...newPaytronUserDetails,
      },
    });
  };

  public render(): ReactElement {
    const {
      user,
      userDetail,
      i18n,
      businessDetails,
      sessionId,
      showSystemErrorMessage,
      isPassfortMigrated,
      websocketEvents,
      paytronUserDetails,
    } = this.state;

    const store: IStore = {
      user,
      setUserData: this.setUserData,
      setUserError: this.setUserError,
      userDetail,
      setUserDetailData: this.setUserDetailData,
      setUserDetailError: this.setUserDetailError,
      i18n,
      setI18n: this.setI18n,
      setBusinessDetails: this.setBusinessDetails,
      businessDetails,
      sessionId,
      setSessionId: this.setSessionId,
      showSystemErrorMessage,
      setShowSystemErrorMessage: this.setShowSystemErrorMessage,
      isPassfortMigrated,
      setPassfortMigrationStatus: this.setPassfortMigrationStatus,
      websocketEvents,
      setWebsocketEvents: this.setWebsocketEvents,
      paytronUserDetails,
      setPaytronUserDetails: this.setPaytronUserDetails,
    };

    return <Store.Provider value={store}>{this.props.children}</Store.Provider>;
  }
}

import { StepConfig } from './pageSteps';
import { businessRegistration, paytronRegistration, cca } from './routes';

export interface PageConfig {
  path: string;
  config: StepConfig;
}

/* Business Registration Pages */
export const businessDetails: PageConfig = {
  path: businessRegistration.businessDetails,
  config: {
    allowedStatuses: [],
    pageName: 'business',
    nextPagePath: businessRegistration.aboutYou,
    stepperLabel: 'Business details',
  },
};

export const businessDetailsToDigitalKyc: PageConfig = {
  path: businessRegistration.businessDetails,
  config: {
    allowedStatuses: [],
    pageName: 'business',
    nextPagePath: businessRegistration.digitalKyc,
    stepperLabel: 'Business details',
  },
};

export const digitalKyc: PageConfig = {
  path: businessRegistration.digitalKyc,
  config: {
    allowedStatuses: [],
    pageName: 'digtalKyc',
    nextPagePath: businessRegistration.aboutYou,
    stepperLabel: 'About your business needs',
  },
};

export const aboutYou: PageConfig = {
  path: businessRegistration.aboutYou,
  config: {
    allowedStatuses: [],
    pageName: 'about',
    nextPagePath: '/',
    stepperLabel: 'About you',
  },
};

export const ownerDetails: PageConfig = {
  path: businessRegistration.ownerDetails,
  config: {
    allowedStatuses: [],
    pageName: 'owner',
    nextPagePath: '/',
    nextPageName: 'upload',
    stepperLabel: 'Shareholders details',
  },
};

export const uploadDocuments: PageConfig = {
  path: businessRegistration.uploadDocuments,
  config: {
    allowedStatuses: [],
    pageName: 'upload',
    stepperLabel: 'Upload documents',
  },
};

/* Paytron Registration Pages */
export const paytronBusinessDetails: PageConfig = {
  path: paytronRegistration.businessDetails,
  config: {
    allowedStatuses: [],
    pageName: 'paytronBusiness',
    nextPagePath: paytronRegistration.verification,
    stepperLabel: 'Business details',
  },
};

/* CCA pages */
const getCcaDefaultAboutYou = (
  path: string,
  configNextPagePath: string,
  nextPageName: string
): PageConfig => {
  return {
    path: path,
    config: {
      ...aboutYou.config,
      allowedStatuses: ['1', '9', '100', 'CCANotStarted', 'CCAStarted'],
      nextStatus: 'CCAShareholdersStarted',
      nextPagePath: configNextPagePath,
      nextPageName: nextPageName,
    },
  };
};

const getCcaDefaultUploadDocuments = (path: string): PageConfig => {
  return {
    path: path,
    config: {
      ...uploadDocuments.config,
      allowedStatuses: ['CCAShareholdersStarted'],
      nextStatus: 'CCADocsUploaded',
    },
  };
};

export const ccaSoleTraderAboutYou: PageConfig = getCcaDefaultAboutYou(
  cca.soleTrader.aboutYou,
  cca.soleTrader.uploadDocuments,
  'upload'
);

export const ccaSoleTraderUploadDocuments: PageConfig =
  getCcaDefaultUploadDocuments(cca.soleTrader.uploadDocuments);

export const ccaNonProfitAboutYou: PageConfig = getCcaDefaultAboutYou(
  cca.nonProfit.aboutYou,
  cca.nonProfit.uploadDocuments,
  'upload'
);

export const ccaNonProfitUploadDocuments: PageConfig =
  getCcaDefaultUploadDocuments(cca.nonProfit.uploadDocuments);

export const ccaPartnershipAboutYou: PageConfig = getCcaDefaultAboutYou(
  cca.partnership.aboutYou,
  cca.partnership.uploadDocuments,
  'upload'
);

export const ccaPartnershipUploadDocuments: PageConfig =
  getCcaDefaultUploadDocuments(cca.partnership.uploadDocuments);

export const ccaPublicCompanyAboutYou: PageConfig = getCcaDefaultAboutYou(
  cca.publicCompany.aboutYou,
  cca.publicCompany.uploadDocuments,
  'upload'
);

export const ccaPublicCompanyUploadDocuments: PageConfig =
  getCcaDefaultUploadDocuments(cca.publicCompany.uploadDocuments);

export const ccaTrustAboutYou: PageConfig = getCcaDefaultAboutYou(
  cca.trust.aboutYou,
  cca.trust.uploadDocuments,
  'upload'
);

export const ccaTrustUploadDocuments: PageConfig = getCcaDefaultUploadDocuments(
  cca.trust.uploadDocuments
);

export const ccaPrivateCompanyAboutYou: PageConfig = getCcaDefaultAboutYou(
  cca.privateCompany.aboutYou,
  cca.privateCompany.ownerDetails,
  'shareholders'
);

export const ccaPrivateCompanyOwnerDetails: PageConfig = {
  path: cca.privateCompany.ownerDetails,
  config: {
    allowedStatuses: ['CCAShareholdersStarted'],
    pageName: 'shareholders',
    nextPagePath: cca.privateCompany.uploadDocuments,
    nextPageName: 'upload',
    stepperLabel: 'Shareholders details',
  },
};

export const ccaPrivateCompanyUploadDocuments: PageConfig =
  getCcaDefaultUploadDocuments(cca.privateCompany.uploadDocuments);
